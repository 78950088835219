import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@components';
import { useTranslation } from '@features/localization';
import { DrugCardUser } from '../DrugCardUser';

export const DrugCardContent = memo(({ drugId, users }) => {
  const { t } = useTranslation();

  return (
    <Box
      customStyles={{
        px: 3.75,
        pt: 2.5,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
        overflow: 'auto',
        boxSizing: 'border-box',
      }}
    >
      {users.map((user) => (
        <DrugCardUser
          key={user.id}
          drugId={drugId}
          userId={user.id}
          name={user.name}
          position={user.position}
          degree={user.degree}
          message={user.message}
        />
      ))}
      {users.length === 0 && (
        <Typography variant='h5' customStyles={{ textAlign: 'center' }}>
          {t('drugs.content.empty')}
        </Typography>
      )}
    </Box>
  );
});

DrugCardContent.displayName = 'DrugCardContent';

DrugCardContent.propTypes = {
  drugId: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      degree: PropTypes.number,
      image: PropTypes.string,
      position: PropTypes.string,
      company: PropTypes.string,
      description: PropTypes.string,
      message: PropTypes.string,
    })
  ),
};

DrugCardContent.defaultProps = {
  users: [],
};
