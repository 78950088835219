import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MUILink from '@mui/material/Link';

export const Link = memo(({ customStyles, variant, href, underline, children, target }) => {
  return (
    <MUILink
      variant={variant}
      href={href}
      underline={underline}
      sx={customStyles}
      rel='noopener'
      target={target}
    >
      {children}
    </MUILink>
  );
});

Link.displayName = 'Link';

Link.propTypes = {
  variant: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  underline: PropTypes.string,
  children: PropTypes.node,
  customStyles: PropTypes.shape({
    color: PropTypes.string,
  }),
};

Link.defaultProps = {
  variant: 'inherit',
  target: '_self',
  href: '#',
  underline: 'always',
  children: null,
  customStyles: {
    color: 'common.white',
  },
};
