/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export const userSelectors = {
  user: (state) => state.user,
  isAuthenticated: (state) => userSelectors.user(state).isAuthenticated,
};

const { actions: userActions, reducer: userReducer } = userSlice;

export { userActions };

export default userReducer;
