import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export function SecondaryButton({
  children,
  component,
  to,
  size,
  disableShadow,
  disabled,
  onClick,
  className,
  customStyles,
}) {
  return (
    <Button
      component={component}
      to={to}
      disableElevation={disableShadow}
      color='secondary'
      variant='contained'
      size={size}
      disabled={disabled}
      onClick={onClick}
      className={className}
      sx={customStyles}
    >
      {children}
    </Button>
  );
}

SecondaryButton.displayName = 'SecondaryButton';

SecondaryButton.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disableShadow: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
};

SecondaryButton.defaultProps = {
  component: null,
  to: '',
  children: null,
  size: 'large',
  disabled: false,
  disableShadow: false,
  className: '',
  customStyles: {},
};
