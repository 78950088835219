import { useSearchParams as _useSearchParams } from 'react-router-dom';
import { DateService } from '@features/app/services';

export function useSearchParams() {
  const [searchParams, setSearchParams] = _useSearchParams();

  return [
    {
      week: searchParams.get('week') || DateService.getWeek(),
      year: searchParams.get('year') || DateService.getFullYear(),
      page: searchParams.get('page') || 1,
    },
    setSearchParams,
  ];
}
