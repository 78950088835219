import { useInfiniteQuery } from '@tanstack/react-query';
import { useShowSnackbar, SNACKBAR_TYPES } from '@features/snackbars';
import { QUERY_KEY_FACTORIES, TIME_UNITS } from '@constants';
import { ApiService } from '@services';
import { selectDrugData, selectDrugsData } from '../../lib';

export function useDrugList({ query = {} } = {}) {
  const showSnackbar = useShowSnackbar();
  const { page, ...drugQuery } = query;

  return useInfiniteQuery({
    queryKey: [...QUERY_KEY_FACTORIES.DRUGS.lists(), { ...drugQuery }],
    queryFn: ({ pageParam = page }) => ApiService.drugs.list({ ...query, page: pageParam }),
    staleTime: TIME_UNITS.HOUR,
    enabled: true,
    getNextPageParam: (lastPage, allPages) => {
      const drugsCount = selectDrugsData({ pages: allPages }).length;

      return drugsCount === lastPage.meta.totalCount ? undefined : +lastPage.params.page + 1;
    },
    select: (response) => {
      const drugs = selectDrugsData(response);

      return drugs.map((drug) => selectDrugData(drug));
    },
    onError: () => {
      showSnackbar(SNACKBAR_TYPES.ERROR);
    },
  });
}
