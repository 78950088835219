import { memo, useCallback } from 'react';
import { Icon, WhiteButton } from '@components';
import AppConfig from '@config';
import { useTranslation } from '@features/localization';
import { useUploadCSV } from '../../hooks';

export const WeekStatisticUploadCSV = memo(() => {
  const { t } = useTranslation();
  const upload = useUploadCSV();
  const handleUpload = useCallback((e) => {
    if (e.target.files && e.target.files.length > 0) {
      upload.mutate(e.target.files[0]);
    }
  }, []);

  // It is a temporary solution until API with messages will be ready
  // then the file has to be removed
  if (AppConfig.apiMessages) {
    return null;
  }

  return (
    <WhiteButton endIcon={<Icon type='upload' width='1rem' />}>
      {t('week_statistic_page.header.upload_csv')}
      <input hidden accept='.csv' type='file' onChange={handleUpload} />
    </WhiteButton>
  );
});

WeekStatisticUploadCSV.displayName = 'WeekStatisticUploadCSV';
