import { Link } from 'react-router-dom';
import { ScreenLayout } from '@components';
import { FOOTER_TYPES } from '@features/app';
import { useTranslation } from '@features/localization';
import { ROUTES } from '@features/navigation';
import { ProductSelectionCard } from '@features/product-selection';

const screenLayout = {
  content: {
    customStyles: {
      mt: 9.5,
      mb: 8.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 9,
    },
  },
  footer: {
    type: FOOTER_TYPES.DEFAULT,
    customStyles: {
      mb: 6.5,
    },
  },
};

export function ProductSelectionPage() {
  const { t } = useTranslation();

  const productsProps = [
    {
      icon: 'spider',
      text: t('product_selection_page.spider'),
      link: {
        component: Link,
        props: {
          to: ROUTES.LOGIN_PAGE,
        },
      },
      color: 'primary.main',
    },
    {
      icon: 'dossier',
      text: t('product_selection_page.dossier'),
      link: {
        component: 'a',
        props: {
          href: 'https://maxinsights.modelate.de/',
        },
      },
      color: 'secondary.main',
    },
  ];

  return (
    <ScreenLayout layout={screenLayout}>
      {productsProps.map((productProps) => (
        <ProductSelectionCard
          key={productProps.icon}
          icon={productProps.icon}
          text={productProps.text}
          link={productProps.link}
          color={productProps.color}
        />
      ))}
    </ScreenLayout>
  );
}

ProductSelectionPage.displayName = 'ProductSelectionPage';
