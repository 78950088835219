import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '@services';
import { QUERY_KEY_FACTORIES, TIME_UNITS } from '@constants';
import { useShowSnackbar, SNACKBAR_TYPES } from '@features/snackbars';
import { DateService } from '@features/app';

export function useDeleteMessage(query) {
  const queryClient = useQueryClient();
  const showSnackbar = useShowSnackbar();

  return useMutation(ApiService.messages.delete, {
    staleTime: TIME_UNITS.HOUR,
    enabled: true,
    onSuccess: async () => {
      queryClient.setQueryData(QUERY_KEY_FACTORIES.CONTACTS.detail(query), (contact) => {
        return {
          ...contact,
          messages: [],
        };
      });

      const contact = queryClient.getQueryData(QUERY_KEY_FACTORIES.CONTACTS.detail(query));
      const drug = contact?.drugs[0];

      const year = DateService.getFullYear(drug?.date);
      const week = DateService.getWeek(drug?.date).toString();

      queryClient.setQueriesData(
        {
          queryKey: [...QUERY_KEY_FACTORIES.DRUGS.lists(), { week, year }],
          exact: false,
        },
        (drugsPages) => ({
          ...drugsPages,
          pages: drugsPages.pages.map((page) => ({
            ...page,
            data: page.data.map((pageDrug) => {
              if (pageDrug.id !== drug.id) return pageDrug;

              return {
                ...pageDrug,
                contacts: pageDrug.contacts.map((drugContact) => {
                  if (drugContact.id !== contact.id) return drugContact;

                  return {
                    ...drugContact,
                    messages: [],
                  };
                }),
              };
            }),
          })),
        })
      );

      queryClient.invalidateQueries(QUERY_KEY_FACTORIES.WEEK_STATISTIC.lists());
    },
    onError: () => {
      showSnackbar(SNACKBAR_TYPES.ERROR);
    },
  });
}
