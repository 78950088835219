import React, { memo, useCallback } from 'react';
import { Box, BackButton, Typography, Switch } from '@components';
import { ROUTES } from '@features/navigation';
import { useReduxAction } from '@hooks';
import { useTranslation, Trans } from '@features/localization';
import { DRUG_TYPES } from '../../constants';
import { drugActions } from '../../store';
import { useDrugType, useSearchParams, usePeriodOfWeek } from '../../hooks';

export const DrugHeader = memo(() => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const setDrugType = useReduxAction(drugActions.setType);
  const drugType = useDrugType();
  const statisticPeriod = usePeriodOfWeek(searchParams);
  const handleSwitch = useCallback(() => {
    if (drugType === DRUG_TYPES.RELEVANT) {
      setDrugType(DRUG_TYPES.ALL);
    } else {
      setDrugType(DRUG_TYPES.RELEVANT);
    }
  }, [drugType]);

  const { startOfWeek, endOfWeek, month, year } = statisticPeriod;

  return (
    <Box
      customStyles={{
        // eslint-disable-next-line no-magic-numbers
        maxWidth: (theme) => `calc(${theme.card.md.width} + ${theme.spacing(2)})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        px: 1,
        boxSizing: 'border-box',
        height: '100%',
        margin: 'auto',
      }}
    >
      <BackButton
        defaultGoBackRoute={ROUTES.WEEK_STATISTIC_PAGE}
        customStyles={{
          mr: 3.5,
        }}
      >
        {t('drugs.header.backward')}
      </BackButton>
      <Typography variant='h4' customStyles={{ color: 'text.disabled', mr: 1.5 }}>
        <Trans
          i18nKey={'drugs.header.week'}
          values={{ week: searchParams.week }}
          components={[<span key={0} />]}
        />
      </Typography>
      <Typography
        variant='h5'
        customStyles={{
          color: 'common.white',
          mr: 5.75,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <Trans
          i18nKey={'drugs.header.period'}
          values={{
            statisticPeriod: `${startOfWeek} - ${endOfWeek} ${month} ${year}`,
          }}
        />
      </Typography>
      <Switch
        onChange={handleSwitch}
        checked={drugType === DRUG_TYPES.RELEVANT}
        startLabel={t('drugs.header.all')}
        endLabel={t('drugs.header.onlyRelevant')}
      />
    </Box>
  );
});

DrugHeader.displayName = 'DrugHeader';
