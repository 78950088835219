import { memo } from 'react';
import MUIBox from '@mui/material/Box';
import PropTypes from 'prop-types';

export const Box = memo(({ customStyles, component, children, className }) => {
  return (
    <MUIBox component={component} sx={customStyles} className={className}>
      {children}
    </MUIBox>
  );
});

Box.displayName = 'Box';

Box.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  children: PropTypes.node,
  customStyles: PropTypes.shape({
    display: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    flexDirection: PropTypes.string,
    gap: PropTypes.number,
  }),
};

Box.defaultProps = {
  className: '',
  component: 'div',
  children: undefined,
  customStyles: {
    display: 'initial',
    alignItems: 'initial',
    justifyContent: 'initial',
    flexDirection: 'initial',
    gap: 0,
  },
};
