import AppConfig from '@config';
import { Base } from './Base';

export class Sessions extends Base {
  login = async () => {
    const baseUrl = AppConfig.apiUrl + AppConfig.apiPrefix;

    // eslint-disable-next-line more/no-window
    window.location.replace(`${baseUrl}/sessions/linkedin`);
  };

  logout = async () => {
    return this.apiClient.delete('/sessions');
  };
}
