import { Fade, ScreenLayout, Delayed } from '@components';
import { FOOTER_TYPES, HEADER_TYPES } from '@features/app';
import { EmptyCard } from '@features/error';
import { MessageCardSkeleton, useContact, useSearchParams } from '@features/message';
import { MessageCardCompleted, MessageCardInProgress } from '@features/message/components';

const screenLayout = {
  content: {
    customStyles: {
      mb: 8.5,
      mx: 'auto',
      px: 2,
      display: 'inline-flex',
      width: '100%',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      justifyContent: 'center',
    },
  },
  header: {
    type: HEADER_TYPES.MESSAGE_PAGE,
    customStyles: {
      mt: 6.5,
    },
  },
  footer: {
    type: FOOTER_TYPES.DEFAULT,
    customStyles: {
      mb: 6.5,
    },
  },
};

export function MessagePage() {
  const searchParams = useSearchParams();
  const { isLoading: isContactLoading, data: contact } = useContact({ query: searchParams });

  function renderCards() {
    if (isContactLoading || !contact) {
      return (
        <Delayed>
          <MessageCardSkeleton />
        </Delayed>
      );
    }

    if (contact?.message) {
      return (
        <MessageCardCompleted
          name={`${contact.firstName} ${contact.lastName}`}
          degree={contact.degree}
          image={contact.image}
          position={contact.position}
          company={contact.company}
          description={contact.description}
          message={contact.message}
        />
      );
    }

    return (
      <MessageCardInProgress
        firstName={contact.firstName}
        lastName={contact.lastName}
        degree={contact.degree}
        image={contact.image}
        position={contact.position}
        company={contact.company}
        description={contact.description}
      />
    );
  }

  if (!isContactLoading && !contact) {
    return (
      <ScreenLayout layout={screenLayout}>
        <EmptyCard customStyles={{ maxWidth: (theme) => theme.card.lg.width }} />
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout layout={screenLayout}>
      <Fade
        customStyles={{
          display: 'inline-flex',
          width: '100%',
          alignItems: 'flex-start',
          boxSizing: 'border-box',
          justifyContent: 'center',
        }}
      >
        {renderCards()}
      </Fade>
    </ScreenLayout>
  );
}

MessagePage.displayName = 'MessagePage';
