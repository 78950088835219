import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Typography } from '@components';

export const ProductSelectionCard = memo(({ link, color, text, icon }) => {
  return (
    <link.component
      {...link.props}
      style={{ textDecoration: 'none', maxWidth: '22rem', width: '100%' }}
    >
      <Card
        customStyles={{
          pt: 9.25,
          px: 2,
          pb: 5.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color,
        }}
      >
        <Icon type={icon} width='6.5rem' />
        <Typography variant='h3' customStyles={{ fontSize: '2.5rem', mt: 5.25 }}>
          {text}
        </Typography>
      </Card>
    </link.component>
  );
});

ProductSelectionCard.displayName = 'ProductSelectionCard';

ProductSelectionCard.propTypes = {
  link: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    props: PropTypes.object,
  }).isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
