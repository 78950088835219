import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

export const TruncateMarkup = memo(({ line, element, truncateText, text, textTruncateChild }) => {
  return (
    <TextTruncate
      line={line}
      element={element}
      truncateText={truncateText}
      text={text}
      textTruncateChild={textTruncateChild}
    />
  );
});

TruncateMarkup.displayName = 'TruncateMarkup';

TruncateMarkup.propTypes = {
  line: PropTypes.number,
  element: PropTypes.string,
  truncateText: PropTypes.string,
  text: PropTypes.string,
  textTruncateChild: PropTypes.node,
};

TruncateMarkup.defaultProps = {
  line: 4,
  element: 'span',
  text: '',
  truncateText: '...',
  textTruncateChild: null,
};
