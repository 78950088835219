import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '@services';
import { useShowSnackbar, SNACKBAR_TYPES } from '@features/snackbars';
import { QUERY_KEY_FACTORIES } from '@constants';

export function useUploadCSV() {
  const queryClient = useQueryClient();
  const showSnackbar = useShowSnackbar();

  return useMutation((file) => ApiService.contacts.create(file), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_FACTORIES.WEEK_STATISTIC.lists());
      await queryClient.invalidateQueries(QUERY_KEY_FACTORIES.DRUGS.lists());
    },
    onError: async () => {
      showSnackbar(SNACKBAR_TYPES.ERROR);
    },
  });
}
