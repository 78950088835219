import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@components';
import { useHistory } from '@features/navigation';

const StyledBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: 0,
  textTransform: 'none',
  '&:hover, &.Mui-focusVisible': {
    color: theme.palette.text.primary,
    background: 'none',
  },
  '&:active': {
    color: theme.palette.common.black,
  },
  ...theme.typography.h4,
}));

export function BackButton({ children, onClick, className, customStyles, defaultGoBackRoute }) {
  const history = useHistory();
  const hasReferrer = history.action !== 'POP';

  return (
    <>
      {(hasReferrer || defaultGoBackRoute) && (
        <StyledBackButton
          component={Link}
          to={hasReferrer ? -1 : defaultGoBackRoute}
          disableRipple
          variant='text'
          size='medium'
          onClick={onClick}
          className={className}
          startIcon={<Icon type='arrow-left' />}
          sx={customStyles}
        >
          {children}
        </StyledBackButton>
      )}
    </>
  );
}

BackButton.displayName = 'BackButton';

BackButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultGoBackRoute: PropTypes.string,
  onClick: PropTypes.func,
  customStyles: PropTypes.shape({
    mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

BackButton.defaultProps = {
  onClick: () => {},
  children: null,
  className: '',
  defaultGoBackRoute: '',
  customStyles: {
    mr: 0,
  },
};
