import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Card } from '@components';
import { WeekStatisticCardHeader } from '../WeekStatisticCardHeader';
import { WeekStatisticCardContent } from '../WeekStatisticCardContent';
import { WeekStatisticCardFooter } from '../WeekStatisticCardFooter';

export const WeekStatisticCard = memo(
  ({ week, year, statisticPeriod, contacts, drugs, totalContactCount, contacted }) => {
    return (
      <Card
        customStyles={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <WeekStatisticCardHeader week={week} statisticPeriod={statisticPeriod} />
        <Box customStyles={{ py: 3.5, px: 6, width: '100%', boxSizing: 'border-box' }}>
          <WeekStatisticCardContent
            contacts={contacts}
            drugs={drugs}
            totalContactCount={totalContactCount}
          />
          <WeekStatisticCardFooter week={week} year={year} contacted={contacted} />
        </Box>
      </Card>
    );
  }
);

WeekStatisticCard.displayName = 'WeekStatisticCard';

WeekStatisticCard.propTypes = {
  week: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  statisticPeriod: PropTypes.shape({
    endOfWeek: PropTypes.string,
    month: PropTypes.string,
    startOfWeek: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
  drugs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      companies: PropTypes.number.isRequired,
    })
  ).isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  totalContactCount: PropTypes.number.isRequired,
  contacted: PropTypes.number.isRequired,
};
