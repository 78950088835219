import PropTypes from 'prop-types';
import { memo } from 'react';
import { Box, Fade, Delayed } from '@components';
import { WeekStatisticCard } from '../WeekStatisticCard';
import { WeekStatisticCardSkeleton } from '../WeekStatisticCardSkeleton';

const PAGE_SIZE = 5;

export const WeekStatisticCardList = memo(({ data, isLoading }) => {
  return (
    <Box
      customStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        maxWidth: (theme) => theme.card.md.width,
        width: '100%',
      }}
    >
      {isLoading || !data?.length
        ? new Array(PAGE_SIZE).fill(0).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Delayed key={i}>
              <Fade>
                <WeekStatisticCardSkeleton />
              </Fade>
            </Delayed>
          ))
        : data.map(
            ({ week, year, statisticPeriod, contacts, drugs, totalContactCount, contacted }) => (
              <Fade key={week}>
                <WeekStatisticCard
                  week={week}
                  year={year}
                  statisticPeriod={statisticPeriod}
                  contacts={contacts}
                  drugs={drugs}
                  totalContactCount={totalContactCount}
                  contacted={contacted}
                />
              </Fade>
            )
          )}
    </Box>
  );
});

WeekStatisticCardList.displayName = 'WeekStatisticCardList';

WeekStatisticCardList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      week: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      statisticPeriod: PropTypes.shape({
        endOfWeek: PropTypes.string,
        month: PropTypes.string,
        startOfWeek: PropTypes.string,
        year: PropTypes.number,
      }).isRequired,
      drugs: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
          companies: PropTypes.number.isRequired,
        })
      ).isRequired,
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
        })
      ).isRequired,
      totalContactCount: PropTypes.number.isRequired,
      messagedContactCount: PropTypes.number.isRequired,
    })
  ),
  isLoading: PropTypes.bool,
};

WeekStatisticCardList.defaultProps = {
  data: null,
  isLoading: false,
};
