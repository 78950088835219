import { useCallback } from 'react';
import { ScreenLayout } from '@components';
import { FOOTER_TYPES, HEADER_TYPES, ShowMoreButton } from '@features/app';
import { useSearchParams, useWeekStatistic } from '@features/week-statistic';
import { WeekStatisticCardList } from '@features/week-statistic/components';
import { EmptyCard } from '@features/error';

const screenLayout = {
  content: {
    customStyles: {
      mb: 8.5,
      mx: 2,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  header: {
    type: HEADER_TYPES.WEEK_STATISTIC,
  },
  footer: {
    type: FOOTER_TYPES.DEFAULT,
    customStyles: {
      mb: 6.5,
    },
  },
};

export function WeekStatisticPage() {
  const [{ page }] = useSearchParams();
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useWeekStatistic({
    query: { page },
  });

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [page]);

  if (!isLoading && data?.length === 0) {
    return (
      <ScreenLayout layout={screenLayout}>
        <EmptyCard />
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout layout={screenLayout}>
      <WeekStatisticCardList data={data} isLoading={isLoading} />
      <ShowMoreButton onClick={handleLoadMore} disabled={isFetchingNextPage || !hasNextPage} />
    </ScreenLayout>
  );
}

WeekStatisticPage.displayName = 'WeekStatisticPage';
