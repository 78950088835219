import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

export function Content({ children, className, customStyles }) {
  const ref = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Box
      ref={ref}
      className={className}
      sx={{ flex: '1 1 0%', position: 'relative', ...customStyles }}
    >
      {children}
    </Box>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  customStyles: PropTypes.object,
};

Content.defaultProps = {
  children: null,
  className: '',
  customStyles: {},
};
