import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// The component has two separated components to render, first is Input, it used in a root DOM node
// Fot Input we use customStyles to set styles
// The second is Menu component is used outside of root DOM node, rendered through the portal
// For menu we use menuCustomStyles because we cannot set styles through the input and do not want to
// make the component too similar to MUI props
export const Select = memo(({ value, onChange, options, customStyles, menuCustomStyles }) => {
  return (
    <MUISelect
      value={value}
      onChange={onChange}
      MenuProps={{
        PaperProps: {
          style: menuCustomStyles,
        },
      }}
      sx={{
        '&.MuiInputBase-root': {
          color: (theme) => theme.palette.primary.main,

          '& .MuiInputBase-input': {
            pt: 0.5,
            pb: 0.5,
            pl: 2,
            fontSize: (theme) => theme.typography.button2.fontSize,
            lineHeight: (theme) => theme.typography.button2.lineHeight,
            letterSpacing: (theme) => theme.typography.button2.letterSpacing,
            fontWeight: (theme) => theme.typography.button2.fontWeight,
          },
          '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
            borderColor: (theme) => theme.palette.primary.main,
          },
        },

        ...customStyles,
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          size='small'
          sx={{
            '&.MuiMenuItem-root': {
              pt: 1,
              pb: 1,
              color: (theme) => theme.palette.text.secondary,
              fontSize: (theme) => theme.typography.button2.fontSize,
              letterSpacing: '0.009rem',

              '&.Mui-selected': {
                backgroundColor: 'action.hover',
              },
            },
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </MUISelect>
  );
});

Select.displayName = 'Select';

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  MenuProps: PropTypes.shape({
    PaperProps: PropTypes.object,
  }),
  customStyles: PropTypes.object,
  menuCustomStyles: PropTypes.object,
};

Select.defaultProps = {
  menuCustomStyles: {},
  customStyles: {},
  MenuProps: {},
};
