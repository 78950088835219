import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export function PrimaryButton({
  children,
  component,
  to,
  size,
  disableShadow,
  endIcon,
  disabled,
  onClick,
  className,
  customStyles,
}) {
  return (
    <Button
      component={component}
      to={to}
      disableElevation={disableShadow}
      color='primary'
      variant='contained'
      size={size}
      disabled={disabled}
      onClick={onClick}
      className={className}
      endIcon={endIcon}
      sx={customStyles}
    >
      {children}
    </Button>
  );
}

PrimaryButton.displayName = 'PrimaryButton';

PrimaryButton.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  to: PropTypes.string,
  endIcon: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  disableShadow: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  customStyles: PropTypes.object,
};

PrimaryButton.defaultProps = {
  children: null,
  component: null,
  to: '',
  size: 'large',
  endIcon: null,
  disabled: false,
  className: '',
  disableShadow: false,
  customStyles: {},
  onClick: () => {},
};
