import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import MUIBox from '@mui/material/Box';

export function Delayed({ children, waitBeforeShow }) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);

    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : <MUIBox sx={{ visibility: 'hidden' }}>{children}</MUIBox>;
}

Delayed.propTypes = {
  waitBeforeShow: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Delayed.defaultProps = {
  waitBeforeShow: 250,
};
