import { memo } from 'react';
import { Box, Card, Skeleton } from '@components';

export const WeekStatisticCardSkeleton = memo(() => {
  return (
    <Card
      customStyles={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        customStyles={{ display: 'flex', width: '100%', boxShadow: (theme) => theme.shadows[26] }}
      >
        <Box
          customStyles={{
            py: 2,
            pl: 6,
            pr: 4,
            boxShadow: (theme) => theme.shadows[25],
            maxWidth: '4.25rem',
            width: '100%',
          }}
        >
          <Skeleton
            variant='text'
            customStyles={{
              fontSize: (theme) => theme.typography.h1.fontSize,
              lineHeight: (theme) => theme.typography.h1.lineHeight,
            }}
          />
        </Box>
        <Box customStyles={{ py: 2, px: 4, width: '100%' }}>
          <Skeleton
            variant='text'
            customStyles={{
              fontSize: (theme) => theme.typography.h2.fontSize,
              lineHeight: (theme) => theme.typography.h2.lineHeight,
            }}
          />
        </Box>
      </Box>
      <Box customStyles={{ py: 3.5, px: 6, width: '100%', boxSizing: 'border-box' }}>
        <Box customStyles={{ mb: 4 }}>
          <Skeleton customStyles={{ mb: 0.5, height: '3.75rem' }} />
          <Skeleton customStyles={{ mb: 0.5, height: '3.75rem' }} />
          <Skeleton customStyles={{ height: '4rem' }} />
        </Box>
        <Box
          customStyles={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box customStyles={{ flex: '0 1 13rem' }}>
            <Skeleton customStyles={{ height: '2.641rem' }} />
          </Box>
          <Box customStyles={{ flex: '0 1 12.5rem' }}>
            <Skeleton customStyles={{ height: '2.641rem' }} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
});

WeekStatisticCardSkeleton.displayName = 'WeekStatisticCardSkeleton';
