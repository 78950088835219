import { DateService } from '@features/app/services';

function getContacted(contacts, contacted) {
  if (contacts === 0) return contacts;

  const decimals = 2;
  const PERCENTAGES = 100;

  return Number(((contacted / contacts) * PERCENTAGES).toFixed(decimals));
}

export function selectWeekStatisticData(weekStatistic) {
  return {
    ...weekStatistic,
    contacted: getContacted(weekStatistic.totalContactCount, weekStatistic.messagedContactCount),
    statisticPeriod: DateService.getPeriodOfWeek(weekStatistic.week, weekStatistic.year),
  };
}
