import React from 'react';
import { ScreenLayout } from '@components';
import { FOOTER_TYPES } from '@features/app';
import { ErrorCard } from '@features/error';

const screenLayout = {
  content: {
    customStyles: {
      mt: 9.5,
      mb: 8.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  footer: {
    type: FOOTER_TYPES.DEFAULT,
    customStyles: {
      mb: 6.5,
    },
  },
};

export function FallbackPage() {
  return (
    <ScreenLayout layout={screenLayout}>
      <ErrorCard />
    </ScreenLayout>
  );
}

FallbackPage.displayName = 'FallbackPage';
