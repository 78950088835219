import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '@services';

export function useLogin() {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.getQueryCache().clear();
  }, []);

  return useMutation(() => ApiService.sessions.login());
}
