import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const StyledTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} variant='standard' {...props} />
))(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    padding: 0,
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
    '& .Mui-disabled': {
      WebkitTextFillColor: theme.palette.text.primary,
    },
  },
}));

export const Textarea = memo(
  ({ value, disabled, onChange, placeholder, error, className, rows, minRows }) => {
    return (
      <StyledTextField
        multiline
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        className={className}
        rows={rows}
        minRows={minRows}
      />
    );
  }
);

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  rows: PropTypes.number,
  minRows: PropTypes.number,
};

Textarea.defaultProps = {
  onChange: () => {},
  error: false,
  className: '',
  rows: undefined,
  minRows: undefined,
  disabled: false,
  placeholder: '',
};
