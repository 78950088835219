import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography } from '@components';
import { ROUTES } from '@features/navigation';
import { useTranslation } from '@features/localization';

export const ErrorNotFoundCard = memo(() => {
  const { t } = useTranslation();

  return (
    <Card
      customStyles={{
        pt: 6.75,
        px: 6.5,
        pb: 8,
        m: 1,
        maxWidth: (theme) => theme.card.sm.width,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography variant='h5' customStyles={{ my: 2 }}>
        {t('error.notFound')}
      </Typography>
      <Link to={ROUTES.WEEK_STATISTIC_PAGE}>{t('error.redirectLink')}</Link>
    </Card>
  );
});

ErrorNotFoundCard.displayName = 'ErrorNotFoundCard';
