import React, { memo } from 'react';
import { useTranslation, Trans } from '@features/localization';
import { DateService } from '@features/app/services';
import { Divider, Link, Box, Typography } from '@components';

export const DefaultFooter = memo(() => {
  const { t } = useTranslation();

  const links = [
    {
      href: 'https://google.com',
      label: t('layout.footer.documentation'),
    },
    {
      href: 'https://google.com',
      label: t('layout.footer.videoTutorials'),
    },
    {
      href: 'https://google.com',
      label: t('layout.footer.termsOfService'),
    },
    {
      href: 'https://google.com',
      label: t('layout.footer.privacyStatement'),
    },
  ];

  return (
    <Box customStyles={{ display: 'flex', gap: 2, alignItems: 'center', flexDirection: 'column' }}>
      <Typography
        component='div'
        variant='caption'
        customStyles={{ color: 'common.white', display: 'flex', gap: 1.5 }}
      >
        {links.map((link, index) => (
          <React.Fragment key={link.label}>
            <Link underline='hover' href={link.href}>
              {link.label}
            </Link>
            {index !== links.length - 1 && (
              <Divider
                customStyles={{ mt: 0.25, mb: 0.25, bgcolor: 'common.white' }}
                orientation='vertical'
                flexItem
              />
            )}
          </React.Fragment>
        ))}
      </Typography>
      <Typography variant='caption' customStyles={{ color: 'common.white' }}>
        <Trans
          i18nKey={'layout.footer.rightsReserved'}
          values={{ year: DateService.getFullYear(new Date()) }}
          components={[<span key={0} />]}
        />
      </Typography>
    </Box>
  );
});

DefaultFooter.displayName = 'DefaultFooter';
