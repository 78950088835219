import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link } from '@components';

export const DrugCardHeader = memo(({ company, drug, date, link }) => {
  return (
    <Box
      customStyles={{
        display: 'flex',
        width: '100%',
        boxShadow: (theme) => theme.shadows[29],
      }}
    >
      <Box
        customStyles={{
          pt: 2.5625,
          px: 3.75,
          pb: 1.8125,
          boxShadow: (theme) => theme.shadows[25],
          maxWidth: '12rem',
          width: '100%',
        }}
      >
        <Typography
          variant='h6'
          customStyles={{
            color: 'primary.main',
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
        >
          {company}
        </Typography>
      </Box>
      <Box
        customStyles={{
          pt: 2.5625,
          pb: 1.8125,
          pl: 3.125,
          pr: 4.75,
          width: '100%',
          gap: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Link
          target='_blank'
          href={link}
          variant='h6'
          underline={link ? 'always' : 'none'}
          customStyles={{
            color: 'text.disabled',
            textDecorationColor: (theme) => theme.palette.text.disabled,
          }}
        >
          {drug}
        </Link>
        <Typography
          variant='h6'
          customStyles={{ color: 'text.disabled', textTransform: 'uppercase' }}
        >
          {date}
        </Typography>
      </Box>
    </Box>
  );
});

DrugCardHeader.displayName = 'DrugCardHeader';

DrugCardHeader.propTypes = {
  company: PropTypes.string.isRequired,
  drug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string,
};

DrugCardHeader.defaultProps = {
  link: '',
};
