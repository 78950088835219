import { createSlice } from '@reduxjs/toolkit';
import { DRUG_TYPES } from '../constants';

const initialState = {
  type: DRUG_TYPES.ALL,
};

export const drugSlice = createSlice({
  name: 'drug',
  initialState,
  reducers: {
    setType: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.type = action.payload;
    },
  },
});

export const drugSelectors = {
  drug: (state) => state.drug,
  type: (state) => drugSelectors.drug(state).type,
};

const { actions: drugActions, reducer: drugReducer } = drugSlice;

export { drugActions };

export default drugReducer;
