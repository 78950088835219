import PropTypes from 'prop-types';
import { memo } from 'react';

import { Box, Textarea } from '@components';

export const MessageCardCompletedContent = memo(({ message }) => {
  return (
    <Box
      customStyles={{
        py: 4,
        px: 9.5,
        width: '100%',
        boxSizing: 'border-box',
        background: (theme) => theme.palette.gradients[2],
      }}
    >
      <Textarea value={message} disabled minRows={6} />
    </Box>
  );
});

MessageCardCompletedContent.displayName = 'MessageCardCompletedContent';

MessageCardCompletedContent.propTypes = {
  message: PropTypes.string.isRequired,
};
