import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Typography, Box } from '@components';
import { useTranslation } from '@features/localization';

// Progress bar breakpoints
const LOW = 25;
const MIDDLE = 50;
const HIGH = 75;
const MAX = 100;

export const WeekStatisticProgressBar = memo(({ value }) => {
  const { t } = useTranslation();

  function getColor() {
    if (value < LOW) {
      return { main: 'error.main', light: 'error.light' };
    } else if (value >= LOW && value < MIDDLE) {
      return { main: 'warning.main', light: 'warning.light' };
    } else if (value >= MIDDLE && value < HIGH) {
      return { main: 'yellow.500', light: 'yellow.400' };
    } else if (value >= HIGH && value < MAX) {
      return { main: 'success.light', light: 'success.light' };
    }

    return { main: 'success.main', light: 'success.main' };
  }

  const color = useMemo(() => getColor(), [value]);
  const valueBuffer = value > HIGH ? MAX : HIGH;

  return (
    <div>
      <Typography variant='body2'>{t('week_statistic_page.contacted')}</Typography>
      <Box customStyles={{ display: 'flex', alignItems: 'center' }}>
        <LinearProgress variant='buffer' color={color} value={value} valueBuffer={valueBuffer} />
        <Typography variant='body2' customStyles={{ color: color.main, ml: 2, fontWeight: 600 }}>
          {value}%
        </Typography>
      </Box>
    </div>
  );
});

WeekStatisticProgressBar.displayName = 'WeekStatisticProgressBar';

WeekStatisticProgressBar.propTypes = {
  value: PropTypes.number,
};

WeekStatisticProgressBar.defaultProps = {
  value: 0,
};
