import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography } from '@components';
import { useTranslation } from '@features/localization';

export const EmptyCard = memo(({ customStyles }) => {
  const { t } = useTranslation();

  return (
    <Card
      customStyles={{
        p: 4,
        m: 1,
        boxSizing: 'border-box',
        width: '100%',
        textAlign: 'center',
        ...customStyles,
      }}
    >
      <Typography variant='body2' customStyles={{ my: 2 }}>
        {t('error.emptyMessage')}
      </Typography>
    </Card>
  );
});

EmptyCard.displayName = 'EmptyCard';

EmptyCard.propTypes = {
  customStyles: PropTypes.shape({
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  }),
};

EmptyCard.defaultProps = {
  customStyles: {
    maxWidth: (theme) => theme.card.md.width,
  },
};
