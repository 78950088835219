/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton, Fade } from '@components';
import { useTranslation } from '@features/localization';

export function ShowMoreButton({ onClick, disabled }) {
  const { t } = useTranslation();

  return (
    <Fade>
      <SecondaryButton customStyles={{ mt: 4 }} onClick={onClick} size='small' disabled={disabled}>
        {t('shared.paginationBtn')}
      </SecondaryButton>
    </Fade>
  );
}

ShowMoreButton.displayName = 'ShowMoreButton';

ShowMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ShowMoreButton.defaultProps = {
  disabled: false,
};
