import { Navigate, Route, Routes } from 'react-router-dom';
import {
  LoginPage,
  MessagePage,
  WeekStatisticPage,
  DrugsPage,
  NotFoundPage,
  ProductSelectionPage,
} from '@pages';
import { ROUTES } from '@features/navigation';

function App() {
  return (
    <Routes>
      <Route path={ROUTES.PRODUCT_SELECTION} element={<ProductSelectionPage />} />
      <Route path={ROUTES.WEEK_STATISTIC_PAGE} element={<WeekStatisticPage />} />
      <Route path={ROUTES.LOGIN_PAGE} element={<LoginPage />} />
      <Route path={ROUTES.MESSAGE} element={<MessagePage />} />
      <Route path={ROUTES.DRUGS} element={<DrugsPage />} />
      <Route path='/' exact element={<Navigate to={ROUTES.PRODUCT_SELECTION} />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
