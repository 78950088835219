import { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Card } from '@components';
import AppConfig from '@config';
import { useSendMessage, useSearchParams } from '@features/message';
import { MessageCardInProgressHeader } from '../MessageCardInProgressHeader';
import { MessageCardInProgressContent } from '../MessageCardInProgressContent';
import { MessageCardInProgressFooter } from '../MessageCardInProgressFooter';

export const MessageCardInProgress = memo(
  ({ firstName, lastName, degree, image, position, company, description }) => {
    const [message, setMessage] = useState('');
    const query = useSearchParams();
    const { isLoading, mutate } = useSendMessage(query);

    // Remove AppConfig.apiMessages when the API for messages will be ready on the backend
    // LINKEDIN PARAMS EXPLANATIONS:
    // company - a company where the contact works
    // firstName - first name of the contact
    // lastName - last name of the contact
    // lastName - last name of the contact
    // network - degree of the contact, ["F"] means the contact is a first degree contact
    const handleSendMessage = useCallback(async () => {
      const payload = {
        text: message,
        drugId: query.drugId,
        contactId: query.userId,
      };

      mutate(payload);

      if (!AppConfig.apiMessages) {
        // eslint-disable-next-line more/no-window
        await window.navigator.clipboard.writeText(message);

        window.open(
          `https://www.linkedin.com/search/results/people/?${qs.stringify({
            company,
            network: '["F"]',
            firstName,
            lastName,
          })}`
        );
      }
    }, [message, query.drugId, query.userId]);

    return (
      <Card
        customStyles={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: (theme) => theme.card.lg.width,
          width: '100%',
        }}
      >
        <MessageCardInProgressHeader
          name={`${firstName} ${lastName}`}
          degree={degree}
          image={image}
          position={position}
          company={company}
          description={description}
        />
        <MessageCardInProgressContent
          userName={`${firstName} ${lastName}`}
          message={message}
          onChange={setMessage}
        />
        <MessageCardInProgressFooter disabled={isLoading || !message} onClick={handleSendMessage} />
      </Card>
    );
  }
);

MessageCardInProgress.displayName = 'MessageCardInProgress';

MessageCardInProgress.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  degree: PropTypes.number.isRequired,
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
  company: PropTypes.string,
  description: PropTypes.string,
};

MessageCardInProgress.defaultProps = {
  image: '',
  company: '',
  description: '',
};
