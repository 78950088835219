import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { FOOTER_TYPES, DefaultFooter } from '@features/app';

export const Footer = memo(({ type, className, customStyles, props }) => {
  function renderFooter() {
    switch (type) {
      case FOOTER_TYPES.DEFAULT:
        // TODO: Put component here when it is gonna be ready
        return <DefaultFooter {...props} />;
      default:
        return null;
    }
  }

  return (
    <Box sx={{ position: 'relative', ...customStyles }} className={className}>
      {renderFooter()}
    </Box>
  );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
  type: PropTypes.oneOf(Object.values(FOOTER_TYPES)),
  className: PropTypes.string,
  props: PropTypes.object,
  customStyles: PropTypes.object,
};

Footer.defaultProps = {
  type: FOOTER_TYPES.TABS,
  className: '',
  props: {},
  customStyles: {},
};
