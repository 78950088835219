import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, PrimaryButton, Icon, Typography } from '@components';
import { useTranslation } from '@features/localization';

export const LoginCard = memo(({ onLogin }) => {
  const { t } = useTranslation();

  return (
    <Card
      customStyles={{
        pt: 6.75,
        px: 6.5,
        pb: 9,
        m: 1,
        maxWidth: (theme) => theme.card.sm.width,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Icon type='logo' width='3.125rem' />
      <Typography variant='body2' customStyles={{ mt: 2, mb: 5, color: 'text.disabled' }}>
        {t('login_page.title')}
      </Typography>
      <PrimaryButton
        onClick={onLogin}
        disableShadow
        customStyles={{ letterSpacing: '0.0625rem', width: '100%' }}
      >
        {t('login_page.button')}
      </PrimaryButton>
    </Card>
  );
});

LoginCard.displayName = 'LoginCard';

LoginCard.propTypes = {
  onLogin: PropTypes.func.isRequired,
};
