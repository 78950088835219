import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { HEADER_TYPES } from '@features/app';
import { MessageHeader } from '@features/message';
import { DrugHeader } from '@features/drug';
import { WeekStatisticHeader } from '@features/week-statistic/components';
import MockHeader from './MockHeader';

export const Header = memo(({ className, type, props, customStyles }) => {
  function renderHeader() {
    switch (type) {
      case HEADER_TYPES.MESSAGE_PAGE:
        return <MessageHeader {...props} />;
      case HEADER_TYPES.WEEK_STATISTIC:
        return <WeekStatisticHeader {...props} />;
      case HEADER_TYPES.DEFAULT:
        return <MockHeader {...props} />;
      case HEADER_TYPES.DRUG_PAGE:
        return <DrugHeader {...props} />;
      default:
        return null;
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        ...customStyles,
      }}
      className={className}
    >
      {renderHeader()}
    </Box>
  );
});

Header.displayName = 'Header';

Header.propTypes = {
  type: PropTypes.oneOf(Object.values(HEADER_TYPES)),
  className: PropTypes.string,
  props: PropTypes.object,
  customStyles: PropTypes.object,
};

Header.defaultProps = {
  type: HEADER_TYPES.HOME,
  className: '',
  props: {},
  customStyles: {},
};
