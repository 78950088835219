import { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@components';
import { Trans } from '@features/localization';
import { MessageCardUserDescription } from '../MessageCardUserDescription';

export const MessageCardCompletedHeader = memo(
  ({ name, degree, image, position, company, description }) => {
    return (
      <Box customStyles={{ display: 'flex', width: '100%', position: 'relative' }}>
        <Box
          customStyles={{
            py: 2,
            pl: 4,
            pr: 2,
            display: 'flex',
            flex: '1 0 15.75rem',
            gap: 2,
            boxShadow: (theme) => theme.shadows[26],
          }}
        >
          <Box>
            <Avatar src={image} />
          </Box>
          <Box>
            <Typography
              variant='h1'
              customStyles={{
                fontSize: '1.25rem',
                color: 'success.main',
                mb: 1,
              }}
            >
              <Trans
                i18nKey={`message.header.user-${degree}`}
                values={{ name }}
                components={[<span key={0} />]}
              />
            </Typography>
            <Typography
              variant='h5'
              customStyles={{ fontSize: '0.875rem', color: 'text.disabled' }}
            >
              {position}
            </Typography>
            <Typography
              variant='h4'
              customStyles={{ fontSize: '0.875rem', color: 'text.main', fontWeight: 700 }}
            >
              {company}
            </Typography>
          </Box>
        </Box>
        <MessageCardUserDescription description={description} />
      </Box>
    );
  }
);

MessageCardCompletedHeader.displayName = 'MessageCardCompletedHeader';

MessageCardCompletedHeader.propTypes = {
  name: PropTypes.string.isRequired,
  degree: PropTypes.number.isRequired,
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
  company: PropTypes.string,
  description: PropTypes.string,
};

MessageCardCompletedHeader.defaultProps = {
  image: '',
  company: '',
  description: '',
};
