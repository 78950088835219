import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MUIFade from '@mui/material/Fade';
import MUIBox from '@mui/material/Box';

export const Fade = memo(({ in: transitionIn, timeout, children, customStyles }) => {
  return (
    <MUIFade in={transitionIn} timeout={timeout} sx={customStyles}>
      <MUIBox>{children}</MUIBox>
    </MUIFade>
  );
});

Fade.displayName = 'Fade';

Fade.propTypes = {
  in: PropTypes.bool,
  customStyles: PropTypes.object,
  children: PropTypes.node,
  timeout: PropTypes.number,
};

Fade.defaultProps = {
  in: true,
  customStyles: {},
  children: null,
  timeout: 1000,
};
