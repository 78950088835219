import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@features/navigation';
import { useIsAuthenticated } from '../../hooks';

export function SessionGate({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (location.pathname === ROUTES.LOGIN_PAGE && isAuthenticated) {
      navigate(ROUTES.WEEK_STATISTIC_PAGE);
    }
  }, [location.pathname]);

  if (location.pathname === ROUTES.LOGIN_PAGE && isAuthenticated) {
    return null;
  }

  return children;
}

SessionGate.propTypes = {
  children: PropTypes.node,
};

SessionGate.defaultProps = {
  children: null,
};
