import { useQuery } from '@tanstack/react-query';
import { useShowSnackbar, SNACKBAR_TYPES } from '@features/snackbars';
import { QUERY_KEY_FACTORIES, TIME_UNITS } from '@constants';
import { ApiService } from '@services';
import { selectContactData } from '../../lib';

export function useContact({ query = {} } = {}) {
  const showSnackbar = useShowSnackbar();

  return useQuery({
    queryKey: QUERY_KEY_FACTORIES.CONTACTS.detail(query),
    queryFn: () => ApiService.contacts.get(query),
    staleTime: TIME_UNITS.HOUR,
    enabled: true,
    select: (contact) => selectContactData(contact),
    onError: () => {
      showSnackbar(SNACKBAR_TYPES.ERROR);
    },
  });
}
