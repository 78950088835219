import { memo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Avatar, Typography, PrimaryButton, Icon, SuccessButton } from '@components';
import { useTranslation, Trans } from '@features/localization';
import { ROUTES } from '@features/navigation';

export const DrugCardUser = memo(({ drugId, userId, name, position, degree, message }) => {
  const { t } = useTranslation();

  return (
    <Box
      customStyles={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        py: 1.75,
        px: 1,
        boxShadow: (theme) => theme.shadows[28],
      }}
    >
      <Box customStyles={{ display: 'flex', width: '40%', alignItems: 'center' }}>
        <Avatar customStyles={{ mr: 2 }} />
        <Trans
          i18nKey={`drugs.content.user-${degree}`}
          values={{ name }}
          components={[
            <Typography
              variant='body1'
              customStyles={{ color: 'text.disabled', lineHeight: 1 }}
              key={0}
            />,
          ]}
        />
      </Box>
      <Box customStyles={{ width: '40%' }}>
        <Typography variant='body1' customStyles={{ color: 'text.disabled' }}>
          {position}
        </Typography>
      </Box>
      <Box customStyles={{ width: '20%' }}>
        {message ? (
          <SuccessButton
            size='small'
            customStyles={{ width: '100%' }}
            component={Link}
            to={generatePath(`${ROUTES.MESSAGE}?drugId=${drugId}&userId=${userId}`)}
          >
            {t('drugs.content.messaged')}
          </SuccessButton>
        ) : (
          <PrimaryButton
            component={Link}
            to={generatePath(`${ROUTES.MESSAGE}?drugId=${drugId}&userId=${userId}`)}
            size='small'
            endIcon={<Icon type='arrow-right' />}
            customStyles={{ width: '100%' }}
          >
            {t('drugs.content.message')}
          </PrimaryButton>
        )}
      </Box>
    </Box>
  );
});

DrugCardUser.displayName = 'DrugCardUser';

DrugCardUser.propTypes = {
  drugId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  degree: PropTypes.number,
  position: PropTypes.string,
  message: PropTypes.string,
};

DrugCardUser.defaultProps = {
  position: '',
  degree: undefined,
  message: '',
};
