import { Base } from './Base';

export class WeekStatistic extends Base {
  list = async (params) => {
    const query = this.createQuery(params);
    const result = await this.apiClient.get('/weeks-statistic', { query });

    return { data: result.data, meta: result.meta, params };
  };
}
