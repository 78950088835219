/* eslint-disable max-lines-per-function */
import { memo } from 'react';
import { Box, Card, Skeleton } from '@components';

export const MessageCardSkeleton = memo(() => {
  return (
    <Card
      customStyles={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: (theme) => theme.card.lg.width,
        width: '100%',
      }}
    >
      <Box
        customStyles={{ display: 'flex', width: '100%', boxShadow: (theme) => theme.shadows[26] }}
      >
        <Box
          customStyles={{
            py: 2,
            pl: 4,
            pr: 2,
            display: 'flex',
            flex: '1 0 15.75rem',
            gap: 2,
          }}
        >
          <Box>
            <Skeleton variant='circular' customStyles={{ width: 24, height: 24 }} />
          </Box>
          <Box>
            <Skeleton
              variant='text'
              customStyles={{
                fontSize: '1.25rem',
                lineHeight: (theme) => theme.typography.h1.lineHeight,
                width: '12rem',
                mb: 1,
              }}
            />
            <Skeleton
              variant='text'
              customStyles={{
                fontSize: '0.875rem',
                lineHeight: (theme) => theme.typography.h5.lineHeight,
                width: '8rem',
              }}
            />
            <Skeleton
              variant='text'
              customStyles={{
                fontSize: '0.875rem',
                lineHeight: (theme) => theme.typography.h4.lineHeight,
                width: '6rem',
              }}
            />
          </Box>
        </Box>
        <Box
          customStyles={{
            py: 2,
            pl: 2,
            pr: 4,
            width: '100%',
            boxShadow: (theme) => theme.shadows[25],
          }}
        >
          <Skeleton
            variant='text'
            customStyles={{
              fontSize: '0.875rem',
              lineHeight: (theme) => theme.typography.body2.lineHeight,
              height: '4.25rem',
            }}
          />
        </Box>
      </Box>
      <Box
        customStyles={{
          pt: 4,
          px: 9.5,
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Skeleton customStyles={{ mb: 4, maxWidth: '9rem', width: '100%', height: '1.875rem' }} />
        <Skeleton customStyles={{ mb: 4, height: '8.25rem' }} />
      </Box>
      <Box
        customStyles={{
          px: 4,
          py: 4,
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box customStyles={{ flex: '0 1 12.5rem' }}>
          <Skeleton customStyles={{ height: '2.641rem' }} />
        </Box>
      </Box>
    </Card>
  );
});

MessageCardSkeleton.displayName = 'MessageCardSkeleton';
