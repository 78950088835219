/* eslint-disable no-irregular-whitespace */

// Here I put name Stefan, because we suppose the user of the app will be the one. So if you want to add other users you
// need to add fetch users from the backend

export function getMessageTemplates({ userName = '' }) {
  return [
    `Dear ${userName},
I hope you are doing fine. I have just seen the great news of the positive FDA decision for your product. As a logic next step, one would think of preparing the commercial launch into Europe. As you know, we have a great and long-standing European network with which you would have a single-point-of-contact to local reimbursement and pricing experts.
Let me know when we could have a call to discuss this further.

Best,
Stefan`,
    `Dear ${userName},
I hope you are doing fine. I have just seen the great news of the positive FDA decision for your product. As a logic next step, one would think of preparing the commercial launch into Europe. As you know, we have successful and extensive experience in payer launches into Europe, with an initiation into the German-speaking markets (D-A-CH, Germany, Austria, Switzerland).

Best,
Stefan`,
    `Dear ${userName},
I hope you are doing fine. I have just seen the great news of the positive FDA decision for your product. As a logic next step, one would think of preparing the commercial launch into Europe. As you know, we have successful and extensive experience in payer launches into Europe, with an initiation into Germany where you could get reimbursement from the minute you have submitted your dossier.

Let me know when we could have a call to discuss this further.
Best,
Stefan`,
  ];
}
