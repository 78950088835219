import { memo } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider as NotisnackProvider } from 'notistack';

export const SnackbarProvider = memo(({ children }) => {
  return (
    <NotisnackProvider
      maxSnack={3}
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {children}
    </NotisnackProvider>
  );
});

SnackbarProvider.displayName = 'SnackbarProvider';

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
