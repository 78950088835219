/* eslint-disable no-magic-numbers */
import dayjs from 'dayjs';

export class DateService {
  static getFullYear(date) {
    return dayjs(date).format('YYYY');
  }

  static getWeek(date) {
    const currentDate = date ? new Date(date) : new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    return weekNumber;
  }

  static getShortDate(date) {
    return dayjs(date).format('DD.MM.YYYY');
  }

  static getPeriodOfWeek(week, year) {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const ISOweekStart = simple;

    if (dayOfWeek <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

    const start = dayjs(ISOweekStart);
    const end = start.add(6, 'day');

    const startOfWeek = start.format('D');
    const endOfWeek = end.format('D');
    const month = end.format('MMMM');

    return { startOfWeek, endOfWeek, month, year };
  }
}
