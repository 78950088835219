import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@components';
import { Trans } from '@features/localization';
import { WeekStatisticCardItem } from '../WeekStatisticCardItem';

export const WeekStatisticCardDrugItem = memo(({ title, value, numberOfCompanies }) => {
  return (
    <WeekStatisticCardItem title={title} value={value}>
      <Box
        customStyles={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          maxWidth: '11.25rem',
          p: 2,
          background: (theme) => theme.palette.grey[370],
        }}
      >
        <Typography
          customStyles={{ display: 'flex', alignItems: 'center' }}
          variant='body1'
          component='div'
        >
          <Trans
            i18nKey={'week_statistic_page.card.companies'}
            values={{ numberOfCompanies }}
            components={[
              <Typography key={0} variant='h5' customStyles={{ fontWeight: 600, mx: 0.5 }} />,
            ]}
          />
        </Typography>
      </Box>
    </WeekStatisticCardItem>
  );
});

WeekStatisticCardDrugItem.displayName = 'WeekStatisticCardDrugItem';
WeekStatisticCardDrugItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  numberOfCompanies: PropTypes.number,
};

WeekStatisticCardDrugItem.defaultProps = {
  numberOfCompanies: 0,
};
