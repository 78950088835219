import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { store } from '@store';
import { appActions } from '@features/app';
import 'dayjs/locale/en';
import 'dayjs/locale/he';

import translationsEN from '../../assets/translations/en.json';

dayjs.extend(localeData);

const resources = {
  en: {
    translation: translationsEN,
  },
};

const fallbackLng = 'en';

class LocalizationService {
  async init() {
    await i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        resources,
        fallbackLng,
        interpolation: {
          escapeValue: false,
        },
      });

    if (!this.getAllSystemLanguages().includes(this.getCurrentLanguage())) {
      await this.changeLanguage(fallbackLng);
    } else {
      await this.changeLanguage(this.getCurrentLanguage());
    }
  }

  generateAcceptLanguageHeader() {
    const languages = i18next.languages.map((lng) => lng.split('-')[0]);
    // for now, we are ignoring the region ("en-US", "en-GB" etc) and just using the language itself
    const uniqueLanguages = [...new Set(languages)];

    return uniqueLanguages.join(', ');
  }

  async changeLanguage(language) {
    await i18next.changeLanguage(language);
    dayjs.locale(language);
    store.dispatch(appActions.setLanguage(language));
  }

  getCurrentLanguage() {
    return i18next.language.split('-')[0];
  }

  getAllSystemLanguages() {
    return Object.keys(resources);
  }

  translate(...args) {
    return i18next.t(...args);
  }
}

export default new LocalizationService();
