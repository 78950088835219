import { useEffect } from 'react';
import { useNavigate, useSearchParams as _useSearchParams } from 'react-router-dom';
import { ROUTES } from '@features/navigation';

export function useSearchParams() {
  const [searchParams] = _useSearchParams();
  const navigate = useNavigate();

  const drugId = searchParams.get('drugId');
  const userId = searchParams.get('userId');

  useEffect(() => {
    if (!drugId || !userId) {
      navigate(ROUTES.WEEK_STATISTIC_PAGE);
    }
  }, [drugId, userId]);

  return {
    drugId,
    userId,
  };
}
