import { DateService } from '@features/app';

export function selectDrugData(drug) {
  return {
    id: drug.id,
    link: drug.fdaLink,
    users: drug.contacts.map((contact) => ({
      ...contact,
      name: `${contact.firstName} ${contact.lastName}`,
      ...(contact?.messages && {
        message: contact.messages.find((message) => message.drugId === drug.id)?.text,
      }),
    })),
    name: drug.name,
    company: drug.company,
    shortDate: DateService.getShortDate(drug.date),
  };
}
