/* eslint-disable no-magic-numbers */
import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

export const theme = createTheme({
  card: {
    sm: {
      width: '458px',
      height: '486px',
    },
    md: {
      width: '766px',
      height: '450px',
    },
    lg: {
      width: '766px',
      height: '458px',
    },
  },
  // It is required because of that not override shadows in other MUI elements. By default MUI has 25 shadows.
  shadows: [
    ...defaultTheme.shadows,
    '0px 1px 4px rgba(13, 0, 94, 0.25), -1px 22px 22px -8px rgba(48, 0, 109, 0.25)',
    '5px 5px 10px rgba(48, 0, 109, 0.1)',
    '-5px 5px 10px rgba(48, 0, 109, 0.1)',
    '0px 1px 1px -1px rgba(0, 0, 0, 0.15), -1px 4px 7px -4px rgba(0, 0, 0, 0.15)',
    '-1px 10px 10px -8px rgba(48, 0, 109, 0.15)',
    '-1px 4px 10px rgba(48, 0, 109, 0.15)',
  ],
  header: {
    height: '56px',
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.43,
    },
    h2: {
      fontWeight: 300,
      fontSize: '1.375rem',
      lineHeight: 1.43,
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.43,
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 1.43,
    },
    h6: {
      lineHeight: 1.43,
    },
    body1: {
      lineHeight: 1.43,
    },
    caption: {
      lineHeight: 1.4,
      fontSize: '0.6rem',
      fontWeight: 500,
    },
    // I do not know why, but component switch has its own styles for typography in the design
    switch: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.0094rem',
    },
    button2: {
      fontSize: '0.8125rem',
      lineHeight: 1.69,
      letterSpacing: 0.46,
      fontWeight: 500,
    },
  },
  palette: {
    gradients: [
      'linear-gradient(75.35deg, #FFD2A2 9.51%, #F433FF 55.1%, #5629EA 84.64%)',
      'linear-gradient(75.35deg, rgba(255, 210, 162, 0.5) 9.51%, rgba(244, 51, 255, 0.5) 55.1%, rgba(86, 41, 234, 0.5) 84.64%)',
      'linear-gradient(90deg, rgba(255, 210, 162, 0.2) 0.75%, rgba(244, 51, 255, 0.2) 49%, rgba(86, 41, 234, 0.2) 97.26%);',
    ],
    primary: {
      main: '#262DC9',
      dark: '#222792',
    },
    secondary: {
      light: '#8E2DF1',
      main: '#9C27B0',
      dark: '#7B1FA2',
    },
    success: {
      light: '#AAE600',
      main: '#00B509',
      dark: '#008B07',
      contrastText: '#fff',
    },
    error: {
      main: '#FF0000',
      light: '#ff999a',
    },
    action: {
      disabledBackground: '#E0E0E0',
      disabled: 'rgba(0, 0, 0, 0.87)',
    },
    warning: {
      main: '#FF8A00',
      light: '#FFD099',
    },
    yellow: {
      500: '#FFC700',
      400: '#FFE999',
    },
    grey: {
      370: '#E0E0E070',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          switch: 'p',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          letterSpacing: 0.46,
        },
        endIcon: ({ ownerState, theme: _theme }) => ({
          ...(ownerState.size === 'small' && {
            // Default spacing value is 8px, set by default in MUI
            '& svg': {
              width: _theme.spacing(1.5),
              height: _theme.spacing(1.5),
            },
          }),
          ...(ownerState.size === 'large' && {
            '& svg': {
              width: _theme.spacing(2),
              height: _theme.spacing(2),
            },
          }),
        }),
        startIcon: ({ ownerState, theme: _theme }) => ({
          ...(ownerState.size === 'medium' && {
            '& svg': {
              width: _theme.spacing(2),
              height: _theme.spacing(2),
            },
          }),
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme: _theme }) => ({
          height: '1.5rem',
          width: '1.5rem',
          filter: `drop-shadow(${_theme.shadows[30]})`,
        }),
        colorDefault: ({ theme: _theme }) => ({
          filter: 'none',
          boxShadow: _theme.shadows[30],
          backgroundColor: _theme.palette.common.white,
          color: _theme.palette.text.disabled,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState, theme: _theme }) => ({
          ...(ownerState.color === 'default' && {
            '& .MuiSwitch-track': {
              backgroundColor: _theme.palette.common.white,
              opacity: 0.5,
            },
            '&.MuiSwitch-root:hover .MuiTouchRipple-root': {
              backgroundColor: _theme.palette.common.white,
              opacity: 0.08,
            },
          }),
        }),
      },
    },
  },
});
