import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@components';
import { DrugCardHeader } from '../DrugCardHeader';
import { DrugCardContent } from '../DrugCardContent';

export const DrugCard = memo(({ name, link, drugId, company, users, date }) => {
  return (
    <Card
      customStyles={{
        maxHeight: (theme) => theme.card.md.height,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        pb: 6,
      }}
    >
      <DrugCardHeader company={company} drug={name} date={date} link={link} />
      <DrugCardContent users={users} drugId={drugId} />
    </Card>
  );
});

DrugCard.displayName = 'DrugCard';

DrugCard.propTypes = {
  date: PropTypes.string.isRequired,
  drugId: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      degree: PropTypes.number,
      image: PropTypes.string,
      position: PropTypes.string,
      company: PropTypes.string,
      description: PropTypes.string,
      message: PropTypes.string,
    })
  ),
};

DrugCard.defaultProps = {
  users: [],
  link: '',
};
