import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appReducer } from '@features/app';
import { drugReducer } from '@features/drug';
import { userReducer } from '@features/user';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['app'],
};

const appPersistConfig = {
  key: 'app',
  storage,
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  drug: drugReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  const persistor = persistStore(store);

  return { store, persistor };
}

const { store, persistor } = createStore();

export { store, persistor };
