import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, SuccessButton } from '@components';
import { useTranslation } from '@features/localization';

export const MessageCardCompletedFooter = memo(({ onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <Box
      customStyles={{
        px: 4,
        py: 4,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <SuccessButton
        size='large'
        onClick={onClick}
        endIcon={<Icon type='arrow-right' />}
        disableShadow
        disabled={disabled}
        customStyles={{
          flex: '0 1 9.625rem',
        }}
      >
        {t('message.footer.messaged')}
      </SuccessButton>
    </Box>
  );
});

MessageCardCompletedFooter.displayName = 'MessageCardCompletedFooter';

MessageCardCompletedFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
