/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import { Box, Card, Skeleton } from '@components';
import { getRandomIntInRange } from '@lib';

export const DrugCardSkeleton = memo(() => {
  const minUsersCount = 1;
  const maxUsersCount = 10;
  const skeletonSize = getRandomIntInRange(minUsersCount, maxUsersCount);

  return (
    <Card
      customStyles={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        pb: 6,
        maxHeight: (theme) => theme.card.md.height,
      }}
    >
      <Box
        customStyles={{ display: 'flex', width: '100%', boxShadow: (theme) => theme.shadows[29] }}
      >
        <Box
          customStyles={{
            pt: 2.5625,
            px: 3.75,
            pb: 1.8125,
            boxShadow: (theme) => theme.shadows[25],
            maxWidth: '12rem',
            width: '100%',
          }}
        >
          <Skeleton
            customStyles={{
              height: '1.75rem',
            }}
          />
        </Box>
        <Box
          customStyles={{
            pt: 2.5625,
            pb: 1.8125,
            pl: 4.375,
            pr: 4.75,
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            gap: 1,
          }}
        >
          <Skeleton
            customStyles={{
              maxWidth: '10rem',
              width: '100%',
              height: '1.75rem',
            }}
          />
          <Skeleton
            customStyles={{
              maxWidth: '7rem',
              width: '100%',
              height: '1.75rem',
            }}
          />
        </Box>
      </Box>
      <Box
        customStyles={{
          px: 3.75,
          pt: 2.5,
          gap: 1,
          overflow: 'auto',
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {new Array(skeletonSize).fill(0).map((_, i) => (
          <Skeleton key={i} customStyles={{ minHeight: '3.625rem' }} />
        ))}
      </Box>
    </Card>
  );
});

DrugCardSkeleton.displayName = 'DrugCardSkeleton';
