import qs from 'query-string';
import { createFormData } from '@lib';
import { bindMiddleware } from './middleware';

export class ApiClient {
  constructor({ url, prefix }) {
    this.url = url;
    this.prefix = prefix;
    this.requestEntry = bindMiddleware(this.fetch);
  }

  get(url, params = {}) {
    return this.request({
      url,
      method: 'GET',
      ...params,
    });
  }

  post(url, params = {}) {
    return this.request({
      url,
      method: 'POST',
      ...params,
    });
  }

  postFormData(url, params) {
    const formData = createFormData(params);

    return this.request({
      url,
      method: 'POST',
      formData,
    });
  }

  put(url, params = {}) {
    return this.request({
      url,
      method: 'PUT',
      ...params,
    });
  }

  delete(url, params = {}) {
    return this.request({
      url,
      method: 'DELETE',
      ...params,
    });
  }

  patch(url, params = {}) {
    return this.request({
      url,
      method: 'PATCH',
      ...params,
    });
  }

  download(url) {
    const absoluteUrl = `${this.url}${this.prefix}${url}`;

    const link = document.createElement('a');

    link.href = absoluteUrl;
    link.download = absoluteUrl;
    link.click();
  }

  #tryParseJSON(data) {
    let result = {};

    try {
      result = JSON.parse(data);
    } catch {
      result = {};
    }

    return result;
  }

  fetch = async ({
    url,
    method,
    body = {},
    headers = {},
    query = {},
    formData,
    signal = new AbortController().signal,
  }) => {
    const config = {
      method,
      signal,
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...(!formData ? { 'Content-Type': 'application/json' } : {}),
        ...headers,
      },
      url,
    };

    if (method !== 'GET' && method !== 'HEAD') {
      config.body = formData ? formData : JSON.stringify(body);
    }

    const querystring = Object.keys(query).length ? `?${qs.stringify(query)}` : '';

    return fetch(`${this.url}${this.prefix}${url}${querystring}`, config);
  };

  async request(params) {
    try {
      const response = await this.requestEntry(params);
      const BAD_SERVER_RESPONSE_RANGE = 400;

      if (response.status >= BAD_SERVER_RESPONSE_RANGE) {
        throw new Error('Bad response from server');
      }

      const rawResponseData = await response.text();
      const parsedData = this.#tryParseJSON(rawResponseData);

      if (parsedData.status === 0) {
        throw parsedData.error;
      }

      return parsedData;
    } catch (error) {
      console.warn('Unhandled exception: ', error);
      throw error;
    }
  }
}
