/* eslint-disable no-magic-numbers */
import React, { memo } from 'react';
import MUISwitch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import { Typography } from '../Typography';
import { Box } from '../Box';

export const Switch = memo(({ checked, onChange, startLabel, endLabel, customStyles }) => {
  return (
    <Box customStyles={customStyles}>
      {startLabel && (
        <Typography variant='switch' customStyles={{ color: 'common.white' }}>
          {startLabel}
        </Typography>
      )}
      <MUISwitch checked={checked} onChange={onChange} color='default' />
      {endLabel && (
        <Typography variant='switch' customStyles={{ color: 'common.white' }}>
          {endLabel}
        </Typography>
      )}
    </Box>
  );
});

Switch.displayName = 'Switch';

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  customStyles: PropTypes.shape({
    display: PropTypes.string,
    alignItems: PropTypes.string,
  }),
};

Switch.defaultProps = {
  startLabel: '',
  endLabel: '',
  customStyles: {
    display: 'flex',
    alignItems: 'center',
  },
};
