import { memo } from 'react';
import { BackButton, Box } from '@components';
import { ROUTES } from '@features/navigation';
import { useTranslation } from '@features/localization';

export const MessageHeader = memo(() => {
  const { t } = useTranslation();

  return (
    <Box
      customStyles={{
        display: 'flex',
        justifyContent: 'center',
        mb: 2,
      }}
    >
      <Box
        customStyles={{
          maxWidth: (theme) => theme.card.md.width,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          p: 2,
        }}
      >
        <BackButton defaultGoBackRoute={ROUTES.DRUGS}>{t('layout.header.backward')}</BackButton>
      </Box>
    </Box>
  );
});

MessageHeader.displayName = 'MessageHeader';
