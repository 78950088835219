import { useSearchParams as _useSearchParams } from 'react-router-dom';

export function useSearchParams() {
  const [searchParams, setSearchParams] = _useSearchParams();

  return [
    {
      page: searchParams.get('page') || 1,
    },
    setSearchParams,
  ];
}
