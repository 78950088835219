import { memo, useCallback } from 'react';
import { WhiteButton, Box, Icon } from '@components';
import { useLogout } from '@features/user';
import { useTranslation } from '@features/localization';
import { WeekStatisticUploadCSV } from '../WeekStatisticUploadCSV';

export const WeekStatisticHeader = memo(() => {
  const { t } = useTranslation();
  const logout = useLogout();

  const handleExit = useCallback(() => {
    logout.mutate();
  }, []);

  return (
    <Box
      customStyles={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        customStyles={{
          maxWidth: (theme) => theme.card.md.width,
          width: '100%',
          display: 'flex',
          p: 2,
        }}
      >
        <WeekStatisticUploadCSV />
        <WhiteButton
          endIcon={<Icon type='exit' />}
          onClick={handleExit}
          customStyles={{ ml: 'auto' }}
        >
          {t('week_statistic_page.header.exit')}
        </WhiteButton>
      </Box>
    </Box>
  );
});

WeekStatisticHeader.displayName = 'WeekStatisticHeader';
