import { enqueueSnackbar } from 'notistack';
import { LocalizationService } from '@features/localization';
import { SNACKBAR_TYPES } from '../constants';

const mapSnackbarTypeToFn = {
  [SNACKBAR_TYPES.SUCCESS]: (snackbarProps) =>
    enqueueSnackbar(
      snackbarProps.message || LocalizationService.translate('snackbars.default.success'),
      { ...snackbarProps, variant: 'success' }
    ),
  [SNACKBAR_TYPES.ERROR]: (snackbarProps) =>
    enqueueSnackbar(
      snackbarProps.message || LocalizationService.translate('snackbars.default.error'),
      { ...snackbarProps, variant: 'error' }
    ),
};

export function showSnackbar(snackbarType, snackbarProps = {}) {
  const fn = mapSnackbarTypeToFn[snackbarType] || enqueueSnackbar;

  return fn(snackbarProps);
}
