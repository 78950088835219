import React, { memo } from 'react';
import MUILinearProgress from '@mui/material/LinearProgress';
import MUIBox from '@mui/material/Box';
import PropTypes from 'prop-types';

export const LinearProgress = memo(({ color, valueBuffer, value }) => {
  return (
    <MUIBox
      sx={{
        width: '100%',
        color: color.main,
        '& .MuiLinearProgress-bar2Buffer': { opacity: 1, color: color.light },
        '& .MuiLinearProgress-dashed': {
          opacity: 1,
          color: color.light,
          backgroundSize: '8px 8px',
          animation: 'none',
          backgroundImage: 'linear-gradient(to right, currentColor 50%, transparent 0%)',
        },
      }}
    >
      <MUILinearProgress variant='buffer' color='inherit' value={value} valueBuffer={valueBuffer} />
    </MUIBox>
  );
});

LinearProgress.displayName = 'LinearProgress';

LinearProgress.propTypes = {
  valueBuffer: PropTypes.number,
  value: PropTypes.number,
  color: PropTypes.shape({
    light: PropTypes.string,
    main: PropTypes.string,
  }),
};

LinearProgress.defaultProps = {
  valueBuffer: 0,
  value: 0,
  color: {
    light: 'primary',
    main: 'primary',
  },
};
