export function createFormData(data) {
  const formData = new FormData();

  createFormDataKey(formData, data);

  return formData;
}

function createFormDataKey(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof Blob)) {
    Object.keys(data).forEach((key) => {
      createFormDataKey(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    formData.append(parentKey, data);
  }
}
