import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@components';
import { Trans } from '@features/localization';

export const WeekStatisticCardHeader = memo(
  ({ week, statisticPeriod: { startOfWeek, endOfWeek, month, year } }) => {
    return (
      <Box
        customStyles={{ display: 'flex', width: '100%', boxShadow: (theme) => theme.shadows[26] }}
      >
        <Box
          customStyles={{
            py: 2,
            pl: 6,
            pr: 4,
            boxShadow: (theme) => theme.shadows[25],
            maxWidth: '4.25rem',
            width: '100%',
          }}
        >
          <Typography
            variant='h1'
            customStyles={{
              color: 'text.disabled',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            }}
          >
            <Trans i18nKey={'week_statistic_page.card.kw'} values={{ week }} />
          </Typography>
        </Box>
        <Box customStyles={{ py: 2, px: 4, width: '100%' }}>
          <Typography
            variant='h2'
            customStyles={{ color: 'primary.main', textTransform: 'uppercase' }}
          >
            <Trans
              i18nKey={'week_statistic_page.card.period'}
              values={{
                statisticPeriod: `${startOfWeek} - ${endOfWeek} ${month} ${year}`,
              }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }
);

WeekStatisticCardHeader.displayName = 'WeekStatisticCardHeader';

WeekStatisticCardHeader.propTypes = {
  week: PropTypes.number.isRequired,
  statisticPeriod: PropTypes.shape({
    endOfWeek: PropTypes.string,
    month: PropTypes.string,
    startOfWeek: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
};
