import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from 'react-error-boundary';
import { CustomRouter, history } from '@features/navigation';
import { LocalizationService } from '@features/localization';
import { SessionGate } from '@features/user';
import { store, persistor } from '@store';
import { SnackbarProvider } from '@features/snackbars';
import { FallbackPage } from '@pages';
import { queryClient } from './queryClient';
import './index.css';
import { theme } from './mui.theme';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

(async () => {
  await LocalizationService.init();
})();

const ReactApp = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomRouter history={history}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <SessionGate>
                <ErrorBoundary FallbackComponent={FallbackPage}>
                  <App />
                </ErrorBoundary>
              </SessionGate>
            </SnackbarProvider>
          </ThemeProvider>
        </CustomRouter>
      </PersistGate>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </Provider>
);

root.render(ReactApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
