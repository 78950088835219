import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const RETRY_LIMIT = 3;

        if (error.code === 'SESSION_REQUIRED') {
          return false;
        }

        return failureCount < RETRY_LIMIT;
      },
    },
  },
});
