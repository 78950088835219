import React, { memo, useCallback } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { typeToIconMap } from './icons';

export const Icon = memo(({ type, width, height, color, className, onClick, ...rest }) => {
  const SVGIcon = typeToIconMap[type];
  const size = width || height;

  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <Box
      component='span'
      sx={{
        display: 'inherit',
        cursor: onClick ? 'pointer' : 'inherit',
        '& > svg': {
          color,
        },
      }}
    >
      <SVGIcon width={size} height={size} className={className} onClick={handleClick} {...rest} />
    </Box>
  );
});

Icon.displayName = 'Icon';

Icon.propTypes = {
  type: PropTypes.oneOf(Object.keys(typeToIconMap)).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  width: '1.5rem',
  height: '1.5rem',
  color: undefined,
  className: '',
  onClick: undefined,
};
