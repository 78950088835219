import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '@assets/icons/arrow-left.svg';
import { ReactComponent as Exit } from '@assets/icons/exit.svg';
import { ReactComponent as User } from '@assets/icons/user.svg';
import { ReactComponent as Logo } from '@assets/icons/logo.svg';
import { ReactComponent as Error } from '@assets/icons/error.svg';
import { ReactComponent as Upload } from '@assets/icons/upload.svg';
import { ReactComponent as Spider } from '@assets/icons/spider.svg';
import { ReactComponent as Dossier } from '@assets/icons/dossier.svg';

export const typeToIconMap = {
  spider: Spider,
  dossier: Dossier,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  exit: Exit,
  user: User,
  upload: Upload,
  logo: Logo,
  error: Error,
};
