import PropTypes from 'prop-types';
import { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from '@features/localization';
import { Box, Select, Textarea } from '@components';
import { getMessageTemplates } from '../../lib';

export const MessageCardInProgressContent = memo(({ message, userName, onChange }) => {
  const { t } = useTranslation();

  const templates = useMemo(() => getMessageTemplates({ userName }), [userName]);
  const options = useMemo(
    () =>
      Object.keys(templates).map((key) => ({
        value: key,
        label: `${t(`message.content.template.${key}`)}`,
      })),
    [templates]
  );
  const [template, setTemplate] = useState(options[0]?.value);
  const handleChangeTemplate = useCallback((event) => {
    const newTemplate = event.target.value;
    const newMessage = templates[newTemplate];

    setTemplate(newTemplate);
    onChange(newMessage);
  }, []);

  useEffect(() => {
    onChange(templates[0]);
  }, []);

  const handleChangeMessage = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [message]
  );

  return (
    <Box
      customStyles={{
        py: 4,
        px: 9.5,
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Select
        value={template}
        onChange={handleChangeTemplate}
        options={options}
        customStyles={{ mb: 4, width: '9rem', maxWidth: '100%' }}
        menuCustomStyles={{ maxHeight: '7.5rem', marginTop: '0.5rem' }}
      />
      <Textarea value={message} onChange={handleChangeMessage} minRows={6} />
    </Box>
  );
});

MessageCardInProgressContent.displayName = 'MessageCardInProgressContent';

MessageCardInProgressContent.propTypes = {
  message: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};
