import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { FOOTER_TYPES, HEADER_TYPES } from '@features/app';
import { Header } from './Header';
import { Footer } from './Footer';
import { Content } from './Content';

export function ScreenLayout({ layout, children }) {
  const { footer, container, content, header } = layout;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflowX: 'hidden',
        background: (theme) => theme.palette.gradients[0],
        ...(container?.customStyles || {}),
      }}
      className={container?.className}
    >
      {header && (
        <Header
          className={header.className}
          type={header.type}
          props={header.props}
          customStyles={header.customStyles}
        />
      )}
      <Content className={content?.className} customStyles={content?.customStyles}>
        {children}
      </Content>
      {footer && (
        <Footer
          type={footer.type}
          customStyles={footer.customStyles}
          className={footer.className}
          props={footer.props}
        />
      )}
    </Box>
  );
}

ScreenLayout.propTypes = {
  layout: PropTypes.shape({
    header: PropTypes.shape({
      className: PropTypes.string,
      type: PropTypes.oneOf(Object.values(HEADER_TYPES)),
      props: PropTypes.object,
      customStyles: PropTypes.object,
    }),
    content: PropTypes.shape({
      className: PropTypes.string,
      customStyles: PropTypes.object,
    }),
    container: PropTypes.shape({
      className: PropTypes.string,
      customStyles: PropTypes.object,
    }),
    footer: PropTypes.shape({
      className: PropTypes.string,
      type: PropTypes.oneOf(Object.values(FOOTER_TYPES)),
      props: PropTypes.object,
      customStyles: PropTypes.object,
    }),
  }),
  children: PropTypes.node,
};

ScreenLayout.defaultProps = {
  layout: {
    header: null,
    content: null,
    container: null,
    footer: null,
  },
  children: null,
};
