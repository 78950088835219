export class Base {
  #pageSize;

  constructor({ apiClient }) {
    if (!apiClient) throw new Error('[apiClient] required');

    this.apiClient = apiClient;
    this.#pageSize = 5;
  }

  // The function exists because usually BE and FE have different query parameters.
  // In case, BE for the project will be changed you will be able to update this function according
  // to new query params that the new BE will have.
  createQuery(query) {
    if (!query) return;

    const { page, ...rest } = query;

    return {
      limit: this.#pageSize,
      offset: (page - 1) * this.#pageSize,
      ...rest,
    };
  }
}
