import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography as MUITypography } from '@mui/material';

const variants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'button',
  'caption',
  'overline',
  'switch',
];

export const Typography = memo(({ variant, children, customStyles, className, component }) => {
  return (
    <MUITypography variant={variant} className={className} component={component} sx={customStyles}>
      {children}
    </MUITypography>
  );
});

Typography.displayName = 'Typography';

Typography.propTypes = {
  variant: PropTypes.oneOf(variants),
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.string,
  customStyles: PropTypes.shape({
    color: PropTypes.string,
    mx: PropTypes.number,
    lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

Typography.defaultProps = {
  variant: 'body2',
  children: null,
  className: '',
  customStyles: {
    color: 'text.secondary',
    fontWeight: 400,
  },
  component: undefined,
};
