import { useInfiniteQuery } from '@tanstack/react-query';
import { userActions, useIsAuthenticated } from '@features/user';
import { useShowSnackbar, SNACKBAR_TYPES } from '@features/snackbars';
import { QUERY_KEY_FACTORIES, TIME_UNITS } from '@constants';
import { useTranslation } from '@features/localization';
import { useReduxAction } from '@hooks';
import { ApiService } from '@services';
import { selectWeekStatisticData, selectWeekStatisticsData } from '@features/week-statistic/lib';

export function useWeekStatistic({ query = {} } = {}) {
  const login = useReduxAction(userActions.login);
  const isAuthenticated = useIsAuthenticated();
  const showSnackbar = useShowSnackbar();
  const { t } = useTranslation();

  return useInfiniteQuery({
    queryKey: QUERY_KEY_FACTORIES.WEEK_STATISTIC.lists(),
    queryFn: ({ pageParam = query.page }) =>
      ApiService.weekStatistic.list({ ...query, page: pageParam }),
    staleTime: TIME_UNITS.HOUR,
    enabled: true,
    select: (response) => {
      const statistics = selectWeekStatisticsData(response);

      return statistics.map((drug) => selectWeekStatisticData(drug));
    },
    // The week statistic is a default page of the app and if data was fetched successfully and user was not logged in
    // We log him in and show a success message.
    onSuccess: () => {
      if (!isAuthenticated) {
        showSnackbar(SNACKBAR_TYPES.SUCCESS, { message: t('user.sign_in.success') });
        login();
      }
    },
    onError: () => {
      showSnackbar(SNACKBAR_TYPES.ERROR);
    },
    getNextPageParam: (lastPage) => {
      const lastPageQuery = ApiService.weekStatistic.createQuery(lastPage.params);
      const isLastPage = lastPageQuery.offset + lastPageQuery.limit >= lastPage.meta.totalCount;

      return isLastPage ? undefined : +lastPage.params.page + 1;
    },
  });
}
