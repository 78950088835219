import { memo } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { Box, Icon, PrimaryButton } from '@components';
import { useTranslation } from '@features/localization';
import { ROUTES } from '@features/navigation';
import { WeekStatisticProgressBar } from '../WeekStatisticProgressBar';

export const WeekStatisticCardFooter = memo(({ week, year, contacted }) => {
  const { t } = useTranslation();

  return (
    <Box
      customStyles={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <Box customStyles={{ flex: '0 1 13rem' }}>
        <WeekStatisticProgressBar value={contacted} />
      </Box>
      <PrimaryButton
        component={Link}
        to={generatePath(`${ROUTES.DRUGS}?year=${year}&week=${week}`)}
        size='large'
        endIcon={<Icon type='arrow-right' />}
        disableShadow
        customStyles={{ flex: '0 1 12.5rem' }}
      >
        {t('week_statistic_page.card.detailsBtn')}
      </PrimaryButton>
    </Box>
  );
});

WeekStatisticCardFooter.displayName = 'WeekStatisticCardFooter';

WeekStatisticCardFooter.propTypes = {
  contacted: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};
