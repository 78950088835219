import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledExitButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: 0,
  '&:hover, &.Mui-focusVisible': {
    color: theme.palette.primary.main,
    background: 'none',
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
  ...theme.typography.h5,
}));

export function WhiteButton({ children, customStyles, onClick, className, endIcon }) {
  return (
    <StyledExitButton
      disableRipple
      color='primary'
      variant='text'
      size='medium'
      component='label'
      onClick={onClick}
      className={className}
      endIcon={endIcon}
      sx={customStyles}
    >
      {children}
    </StyledExitButton>
  );
}

WhiteButton.displayName = 'WhiteButton';

WhiteButton.propTypes = {
  endIcon: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  customStyles: PropTypes.object,
};

WhiteButton.defaultProps = {
  children: null,
  endIcon: null,
  onClick: undefined,
  customStyles: {},
  className: '',
};
