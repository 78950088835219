import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MUISkeleton from '@mui/material/Skeleton';

export const Skeleton = memo(({ customStyles, variant, animation, children }) => {
  return (
    <MUISkeleton
      variant={variant}
      animation={animation}
      // In MUI you have two sources of truth for the width and height. I united it in the customStyles.
      sx={customStyles}
      height={customStyles.height}
      width={customStyles.width}
    >
      {children}
    </MUISkeleton>
  );
});

Skeleton.displayName = 'Skeleton';

Skeleton.propTypes = {
  variant: PropTypes.string,
  animation: PropTypes.string,
  children: PropTypes.node,
  customStyles: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

Skeleton.defaultProps = {
  variant: 'rounded',
  animation: 'pulse',
  children: null,
  customStyles: {
    width: 'auto',
    height: 'auto',
  },
};
