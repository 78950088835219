import AppConfig from '@config';
import { ApiClient } from './ApiClient';
import { Sessions, WeekStatistic, Drugs, Messages, Contacts } from './entities';

class ApiService {
  constructor({ apiUrl, apiPrefix } = {}) {
    if (!apiUrl || !apiPrefix) {
      throw new Error('Invalid apiUrl/apiPrefix');
    }

    const apiClient = new ApiClient({ url: apiUrl, prefix: apiPrefix });

    this.drugs = new Drugs({ apiClient });
    this.weekStatistic = new WeekStatistic({ apiClient });
    this.sessions = new Sessions({ apiClient });
    this.messages = new Messages({ apiClient });
    this.contacts = new Contacts({ apiClient });
  }
}

export default new ApiService({
  apiUrl: AppConfig.apiUrl,
  apiPrefix: AppConfig.apiPrefix,
});
