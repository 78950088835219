import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MUIDivider from '@mui/material/Divider';

export const Divider = memo(({ customStyles, orientation, flexItem, children }) => {
  return (
    <MUIDivider
      flexItem={flexItem}
      orientation={orientation}
      sx={{ borderRightWidth: 1.5, ...customStyles }}
    >
      {children}
    </MUIDivider>
  );
});

Divider.displayName = 'Divider';

Divider.propTypes = {
  flexItem: PropTypes.bool,
  orientation: PropTypes.string,
  children: PropTypes.node,
  customStyles: PropTypes.shape({
    bgcolor: PropTypes.string,
    mt: PropTypes.number,
    mb: PropTypes.number,
  }),
};

Divider.defaultProps = {
  flexItem: false,
  orientation: 'horizontal',
  children: undefined,
  customStyles: {
    bgcolor: 'common.white',
  },
};
