import React, { memo } from 'react';
import { Card, Icon, Typography } from '@components';
import { useTranslation } from '@features/localization';

export const ErrorCard = memo(() => {
  const { t } = useTranslation();

  return (
    <Card
      customStyles={{
        pt: 6.75,
        px: 6.5,
        pb: 8,
        m: 1,
        maxWidth: (theme) => theme.card.sm.width,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Icon color='error.light' type='error' width='5rem' />
      <Typography variant='h5' customStyles={{ my: 2 }}>
        {t('error.title')}
      </Typography>
    </Card>
  );
});

ErrorCard.displayName = 'ErrorCard';
