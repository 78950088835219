export function selectContactData(contact) {
  const [firstName, lastName] = contact.name?.split(' ') || [];

  return {
    firstName: contact.firstName || firstName,
    lastName: contact.lastName || lastName,
    degree: contact.degree,
    image: contact?.image,
    position: contact.position,
    company: contact.company,
    description: contact.description,
    message: contact?.messages[0],
  };
}
