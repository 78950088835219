import { Base } from './Base';

export class Contacts extends Base {
  get = async ({ drugId, userId }) => {
    const result = await this.apiClient.get(`/contacts/${userId}/drugs/${drugId}`);

    return result?.data;
  };

  create = async (file) => {
    const response = await this.apiClient.postFormData('/contacts/csv', { file });

    return response;
  };
}
