import { Base } from './Base';

export class Drugs extends Base {
  list = async (params) => {
    const { week, ...rest } = params;
    const result = await this.apiClient.get(`/drugs/${week}`, { query: this.createQuery(rest) });

    // I had to pass params back in a response because we use it in useInfiteQuery to get the next params for the requet
    return { meta: result.meta, data: result.data, params };
  };
}
