import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

export function useReduxAction(action) {
  const dispatch = useDispatch();

  return useCallback(
    (...args) => {
      dispatch(action(...args));
    },
    [dispatch]
  );
}
