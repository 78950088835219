import { store } from '@store';
import { userActions } from '@features/user/store';
import { history, ROUTES } from '@features/navigation';
import { ERROR_CODES } from '@features/error';

class SessionMiddleware {
  runner = async (nested, params) => {
    const response = await nested(params);

    this.#checkSession(response);

    return response;
  };

  async #checkSession(response) {
    const contentType = response.headers.get('content-type');

    if (contentType.includes('application/json')) {
      const clonedReponse = response.clone();

      try {
        const json = await clonedReponse.json();
        const { isAuthenticated } = store.getState().user;

        if (json.error?.code === ERROR_CODES.SESSION_REQUIRED && isAuthenticated) {
          store.dispatch(userActions.logout());
        }

        if (json.error?.code === ERROR_CODES.SESSION_REQUIRED) {
          history.push(ROUTES.LOGIN_PAGE);
        }
      } catch (err) {
        console.error('Cannot check SESSION status. Failed to parse response body', err);
      }
    }
  }
}

const sessionMiddleware = new SessionMiddleware();

export default sessionMiddleware.runner;
