import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@components';
import { useDeleteMessage, useSearchParams } from '@features/message';
import { MessageCardCompletedHeader } from '../MessageCardCompletedHeader';
import { MessageCardCompletedContent } from '../MessageCardCompletedContent';
import { MessageCardCompletedFooter } from '../MessageCardCompletedFooter';

export const MessageCardCompleted = memo(
  ({ name, degree, image, position, company, description, message }) => {
    const query = useSearchParams();
    const { isLoading, mutate } = useDeleteMessage(query);

    const handleDeleteMessage = useCallback(async () => {
      mutate(message.id);
    }, []);

    return (
      <Card
        customStyles={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: (theme) => theme.card.lg.width,
          width: '100%',
        }}
      >
        <MessageCardCompletedHeader
          name={name}
          degree={degree}
          image={image}
          position={position}
          company={company}
          description={description}
        />
        <MessageCardCompletedContent message={message.text} />
        <MessageCardCompletedFooter onClick={handleDeleteMessage} disabled={isLoading} />
      </Card>
    );
  }
);

MessageCardCompleted.displayName = 'MessageCardCompleted';

MessageCardCompleted.propTypes = {
  name: PropTypes.string.isRequired,
  degree: PropTypes.number.isRequired,
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
  company: PropTypes.string,
  description: PropTypes.string,
  message: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.number,
  }),
};

MessageCardCompleted.defaultProps = {
  image: '',
  company: '',
  description: '',
  message: {
    text: '',
  },
};
