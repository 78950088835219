import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MUICard from '@mui/material/Card';

export const Card = memo(({ children, customStyles }) => {
  return <MUICard sx={{ borderRadius: 5, boxShadow: 26, ...customStyles }}>{children}</MUICard>;
});

Card.displayName = 'Card';

Card.propTypes = {
  customStyles: PropTypes.object,
  children: PropTypes.node,
};

Card.defaultProps = {
  customStyles: {},
  children: null,
};
