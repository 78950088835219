export const QUERY_KEY_FACTORIES = {
  WEEK_STATISTIC: {
    all: () => {
      return ['week-statistic'];
    },
    lists: () => {
      return [...QUERY_KEY_FACTORIES.WEEK_STATISTIC.all(), 'list'];
    },
    list: (query) => {
      return [...QUERY_KEY_FACTORIES.WEEK_STATISTIC.lists(), query];
    },
  },
  DRUGS: {
    all: () => {
      return ['drugs'];
    },
    lists: () => {
      return [...QUERY_KEY_FACTORIES.DRUGS.all(), 'list'];
    },
    list: (query) => {
      return [...QUERY_KEY_FACTORIES.DRUGS.lists(), query];
    },
  },
  CONTACTS: {
    all: () => {
      return ['contacts'];
    },
    details: () => {
      return [...QUERY_KEY_FACTORIES.CONTACTS.all(), 'details'];
    },
    detail: (query) => {
      return [...QUERY_KEY_FACTORIES.CONTACTS.details(), query];
    },
  },
};
