/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { ScreenLayout } from '@components';
import { FOOTER_TYPES, HEADER_TYPES, ShowMoreButton } from '@features/app';
import { DrugCardList, useDrugList, useDrugType, useSearchParams } from '@features/drug';
import { EmptyCard } from '@features/error';

export function DrugsPage() {
  const drugType = useDrugType();
  const [searchParams] = useSearchParams();
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useDrugList({
    query: { ...searchParams, type: drugType },
  });
  const screenLayout = {
    header: {
      type: HEADER_TYPES.DRUG_PAGE,
      customStyles: {
        zIndex: 1,
        width: '100%',
        position: 'fixed',
        backdropFilter: 'blur(0.9375rem)',
        height: (theme) => theme.header.height,
        background: (theme) => theme.palette.gradients[1],
      },
    },
    content: {
      customStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // eslint-disable-next-line no-magic-numbers
        mt: (theme) => `calc(${theme.header.height} + ${theme.spacing(3.75)})`,
        zIndex: 0,
        mx: 1,
        mb: 8.75,
      },
    },
    footer: {
      type: FOOTER_TYPES.DEFAULT,
      customStyles: {
        mb: 6.5,
      },
    },
  };

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [searchParams.page]);

  if (!isLoading && data?.length === 0) {
    return (
      <ScreenLayout layout={screenLayout}>
        <EmptyCard />
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout layout={screenLayout}>
      <DrugCardList isLoading={isLoading} drugs={data} />
      <ShowMoreButton onClick={handleLoadMore} disabled={isFetchingNextPage || !hasNextPage} />
    </ScreenLayout>
  );
}

DrugsPage.displayName = 'DrugsPage';
