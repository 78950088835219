import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@components';

export const WeekStatisticCardItem = memo(({ title, value, children }) => {
  return (
    <Box
      customStyles={{ display: 'flex', minHeight: '4rem', boxShadow: (theme) => theme.shadows[28] }}
    >
      <Box
        customStyles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '3.625rem',
          boxShadow: (theme) => theme.shadows[26],
        }}
      >
        <Typography variant='h1' customStyles={{ color: 'text.secondary' }}>
          {value}
        </Typography>
      </Box>
      <Box
        customStyles={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box customStyles={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Typography variant='body1'>{title}</Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
});

WeekStatisticCardItem.displayName = 'WeekStatisticCardItem';
WeekStatisticCardItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

WeekStatisticCardItem.defaultProps = {
  children: null,
};
