import PropTypes from 'prop-types';
import { memo } from 'react';
import { Box, Fade, Delayed } from '@components';
import { DrugCard } from '../DrugCard';
import { DrugCardSkeleton } from '../DrugCardSkeleton';

export const DrugCardList = memo(({ drugs, isLoading }) => {
  const SKELETON_SIZE = 5;

  return (
    <Box
      customStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3.75,
        maxWidth: (theme) => theme.card.md.width,
        width: '100%',
        height: 'auto',
        mx: 'auto',
      }}
    >
      {isLoading
        ? new Array(SKELETON_SIZE).fill(0).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Delayed key={i}>
              <Fade>
                <DrugCardSkeleton />
              </Fade>
            </Delayed>
          ))
        : drugs?.map((drug) => (
            <Fade key={drug.id}>
              <DrugCard
                link={drug.link}
                name={drug.name}
                company={drug.company}
                users={drug.users}
                date={drug.shortDate}
                drugId={drug.id}
              />
            </Fade>
          ))}
    </Box>
  );
});

DrugCardList.displayName = 'DrugCardList';

DrugCardList.propTypes = {
  drugs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      company: PropTypes.string.isRequired,
      link: PropTypes.string,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          degree: PropTypes.number,
          image: PropTypes.string,
          position: PropTypes.string,
          company: PropTypes.string,
          description: PropTypes.string,
          message: PropTypes.string,
        })
      ),
    })
  ),
  isLoading: PropTypes.bool,
};

DrugCardList.defaultProps = {
  drugs: undefined,
  isLoading: false,
};
