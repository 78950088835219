import { Base } from './Base';

export class Messages extends Base {
  send = async (payload) => {
    const result = await this.apiClient.post(`/messages`, { body: payload });

    return result?.data;
  };

  delete = async (id) => {
    const result = await this.apiClient.delete(`/messages/${id}`);

    return result?.data;
  };
}
