import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@components';
import { useTranslation } from '@features/localization';
import { WeekStatisticCardItem } from '../WeekStatisticCardItem';
import { WeekStatisticCardDrugItem } from '../WeekStatisticCardDrugItem';

export const WeekStatisticCardContent = memo(({ contacts, drugs }) => {
  const { t } = useTranslation();

  return (
    <Box customStyles={{ mb: 4 }}>
      {drugs.map(({ type, count, companies: numberOfCompanies }) => (
        <WeekStatisticCardDrugItem
          key={type}
          title={t(`week_statistic_page.card.type.${type.toLowerCase()}`)}
          value={count}
          numberOfCompanies={numberOfCompanies}
        />
      ))}
      <WeekStatisticCardItem
        title={t(`week_statistic_page.card.type.contacts`)}
        value={contacts[0].count}
      />
    </Box>
  );
});

WeekStatisticCardContent.displayName = 'WeekStatisticCardContent';

WeekStatisticCardContent.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  drugs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      companies: PropTypes.number.isRequired,
    })
  ).isRequired,
};
