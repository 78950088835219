import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, PrimaryButton } from '@components';
import { useTranslation } from '@features/localization';

export const MessageCardInProgressFooter = memo(({ disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <Box
      customStyles={{
        px: 4,
        py: 4,
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <PrimaryButton
        size='large'
        endIcon={<Icon type='arrow-right' />}
        disableShadow
        customStyles={{
          flex: '0 1 9.625rem',
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {t('message.footer.message')}
      </PrimaryButton>
    </Box>
  );
});

MessageCardInProgressFooter.displayName = 'MessageCardInProgressFooter';

MessageCardInProgressFooter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
