import { memo } from 'react';
import PropTypes from 'prop-types';
import MUIAvatar from '@mui/material/Avatar';

export const Avatar = memo(({ src, alt, customStyles }) => {
  return <MUIAvatar src={src} alt={alt} sx={customStyles} />;
});

Avatar.displayName = 'Avatar';

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  customStyles: PropTypes.shape({
    mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

Avatar.defaultProps = {
  src: undefined,
  alt: 'avatar',
  customStyles: {
    mr: 0,
  },
};
