import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ROUTES } from '@features/navigation';
import { userActions } from '@features/user/store';
import { useReduxAction } from '@hooks';
import { ApiService } from '@services';

export function useLogout() {
  const logout = useReduxAction(userActions.logout);
  const navigate = useNavigate();

  return useMutation(() => ApiService.sessions.logout(), {
    onSuccess: () => {
      logout();
      navigate(ROUTES.PRODUCT_SELECTION);
    },
  });
}
