import React, { useCallback } from 'react';
import { ScreenLayout } from '@components';
import { FOOTER_TYPES } from '@features/app';
import { useLogin, LoginCard } from '@features/user';

const screenLayout = {
  content: {
    customStyles: {
      mt: 9.5,
      mb: 8.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  footer: {
    type: FOOTER_TYPES.DEFAULT,
    customStyles: {
      mb: 6.5,
    },
  },
};

export function LoginPage() {
  const login = useLogin();

  const handleLogin = useCallback(async () => {
    try {
      await login.mutateAsync();
    } catch (err) {
      // TODO: Add a handler for an error when the handler is gonna be ready in a design
      console.error(err);
    }
  }, []);

  return (
    <ScreenLayout layout={screenLayout}>
      <LoginCard onLogin={handleLogin} />
    </ScreenLayout>
  );
}

LoginPage.displayName = 'LoginPage';
