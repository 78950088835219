/* eslint-disable no-magic-numbers */
import { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, TruncateMarkup } from '@components';
import { useTranslation } from '@features/localization';

export const MessageCardUserDescription = memo(({ description }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = useCallback(() => setShowMore((prev) => !prev), []);

  return (
    <Box
      customStyles={{
        py: 2,
        pl: 2,
        pr: 4,
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: (theme) => theme.shadows[25],
        ...(showMore && {
          position: 'absolute',
          width: 'auto',
          maxHeight: (theme) => `calc(${theme.card.lg.height} * 0.7)`,
          overflow: 'auto',
          left: '18.75rem',
          zIndex: 100,
        }),
        backgroundColor: 'common.white',
      }}
    >
      {showMore ? (
        <Box customStyles={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Typography
            variant='body2'
            customStyles={{
              fontSize: '0.8rem',
            }}
          >
            {description}
          </Typography>
          <Button
            onClick={toggleShowMore}
            customStyles={{
              py: 0,
              fontSize: '0.75rem',
              textTransform: 'none',
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {t('message.header.seeLess')}
          </Button>
        </Box>
      ) : (
        <Typography
          variant='body2'
          customStyles={{
            fontSize: '0.8rem',
          }}
        >
          <TruncateMarkup
            line={4}
            element='span'
            truncateText='…'
            text={description}
            textTruncateChild={
              <Button
                onClick={toggleShowMore}
                customStyles={{
                  py: 0,
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {t('message.header.seeMore')}
              </Button>
            }
          />
        </Typography>
      )}
    </Box>
  );
});

MessageCardUserDescription.displayName = 'MessageCardUserDescription';

MessageCardUserDescription.propTypes = {
  description: PropTypes.string,
};

MessageCardUserDescription.defaultProps = {
  description: '',
};
